import React, { ReactNode, useState } from 'react';
import { Avatar, Table, Input } from 'antd';
import { ColumnProps } from 'antd/es/table';
import "./DotctorsTable.style.scss";

import { IAppointment } from '../../../../../interfaces/patient';
import { IUser } from "../../../../../interfaces/user";

const columns: ColumnProps<IUser>[] = [
  {
    key: 'fullName',
    title: 'Nom Complet',
    sorter: (a, b) =>
      (a.firstName + " " + a.lastName > b.firstName + " " + b.lastName ? 1 : -1),
    render: (patient) => (
      <strong className='nowrap'>
        {patient.firstName + " " + patient.lastName}
      </strong>
    )
  },
  {
    key: 'username',
    dataIndex: 'username',
    title: 'Nom d\'utilisateur',
    sorter: (a, b) =>
      (a.username > b.username ? 1 : -1),
    render: (username) => (
      <strong className='nowrap'>
        {username}
      </strong>
    )
  },
  {
    key: 'email',
    dataIndex: 'email',
    title: 'Email',
    sorter: (a, b) =>
      (a.email > b.email ? 1 : -1),
    render: (email) => (
      <strong>{email}</strong>
    )
  },
  {
    key: 'phone',
    dataIndex: 'phone',
    title: 'Téléphone',
    sorter: (a, b) =>
      (a.phone > b.phone ? 1 : -1),
    render: (phone) => (
      <strong>+216 {phone}</strong>
    )
  },
];

type Props = {
  data: IUser[];
  actions?: (doctor: IUser) => ReactNode;
};

const AdminDoctorsTable = ({ data, actions }: Props) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter(doctor =>
    doctor.username.toLowerCase().includes(searchText.toLowerCase()) ||
    doctor.email.toLowerCase().includes(searchText.toLowerCase()) ||
    (doctor.firstName?.toLowerCase() + " " +
      doctor.lastName?.toLowerCase()).includes(searchText.toLowerCase())
  );

  return (
    <>
      <label className="search-label">Rechercher</label>
      <Input
        className="col-md-6 col-sm-12 search-input"
        placeholder="Rechercher par nom d'utilisateur, email, ou nom complet"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
      />
      <Table
        rowKey='_id'
        dataSource={filteredData}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};

export default AdminDoctorsTable;
