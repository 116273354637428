import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Spin, Select } from 'antd'; // Import Spin for the spinner
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../../../utils/hasError';
import { IReport } from '../../../../../../../interfaces/patient';
import "./reportForm.scss";
import api from "../../../../../../../api";
import { selectOptionFilter } from '../../../../../../../hooks/selectOptionFilter';

type Props = {
  patientId?: string;
  report?: IReport;
  onSubmit: (report: IReport) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Ajouter un rapport';
const emptyReport = {
  fileName: '',
  fileStream: null,
  title: '',
  type: '',
  date: null,
  patientId: '',
  bucketName: 'rapport'
};

const reportSchema = Yup.object().shape({
  title: Yup.mixed().required('Le titre est requis'),
  type: Yup.mixed().required('Le type est requis'),
  fileStream: Yup.mixed().required('Le fichier est requis')
});

const ReportForm = ({
  patientId,
  submitText = defaultSubmitText,
  report = emptyReport,
  onSubmit,
  onCancel
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission
  const [fileError, setFileError] = useState(''); // State for file upload errors

  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    values,
    setValues,
    handleSubmit,
    isValid,
    errors,
    touched,
    resetForm,
    setFieldValue
  } = useFormik<IReport>({
    validationSchema: reportSchema,
    initialValues: report,
    onSubmit: async (form) => {
      setIsSubmitting(true); // Start submission
      try {
        console.log({ ...form });
        await addReport(values);
        onSubmit({ ...values });
        resetForm();
      } finally {
        setIsSubmitting(false); // End submission
      }
    }
  });

  const addReport = async (values: IReport) => {
    try {
      console.log("adding");
      const formData = new FormData();
      formData.append('fileStream', values.fileStream as unknown as File);
      formData.append('fileName', values.fileName);
      formData.append('type', values.type);
      formData.append('title', values.title);
      formData.append('date', values.date);
      formData.append('patientId', values.patientId);
      formData.append('bucketName', values.bucketName);
      await api.post("report", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setValues({ ...report, patientId: patientId });
  }, [report]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const hasError = hasErrorFactory(touched, errors);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        setFileError('Le fichier doit être au format PDF');
        return;
      }
      setFileError('');
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      setFieldValue('fileName', fileNameWithoutExtension);
      setFieldValue('fileStream', file);
    }
  };

  const handleTypeSelect = (value) => setFieldValue('type', value);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor='title'>Titre</label>
          <Input
            type="text"
            id='title'
            name="title"
            placeholder="Titre"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.title}
            className={hasError('title')}
          />
          {touched.title && errors.title && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.title)}
            </div>
          )}
        </div>

        <div className='form-group'>
          <label htmlFor='type'>Type</label>
          <Select
            id='type'
            showSearch
            placeholder='Sélectionner le type'
            optionFilterProp='children'
            filterOption={selectOptionFilter}
            onChange={handleTypeSelect}
            onBlur={() => setFieldTouched('type')}
            className={hasError('type')}
            value={values.type}
          >
            <Select.Option value="Diagnostiques" style={{color: "blue"}}>Diagnostiques</Select.Option>
            <Select.Option value="Essai de prothése" style={{color: "red"}}>Essai de prothése</Select.Option>
            <Select.Option value="Contrôle d'appareillage" style={{color: "green"}}>Contrôle d'appareillage</Select.Option>
            <Select.Option value="Anamnèse" style={{color: "yellow"}}>Anamnèse</Select.Option>
            <Select.Option value="Test d'audition" style={{color: "black"}}>Test d'audition</Select.Option>
          </Select>
          {touched.type && errors.type && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.type)}
            </div>
          )}
        </div>

        <Form.Item>
          <Input
            name='fileStream'
            placeholder='Télécharger le fichier'
            type='file'
            onBlur={handleBlur}
            onChange={handleFileChange}
            style={{ cursor: 'pointer' }}
            className={`fileStream ${hasError('fileStream')}`}
          />
          {fileError && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {fileError}
            </div>
          )}
          {touched.fileStream && errors.fileStream && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.fileStream)}
            </div>
          )}
        </Form.Item>

        {isSubmitting && <strong style={{ color: "red" }}>Chargement du fichier en cours...</strong>} {/* Display message while submitting */}

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel} disabled={isSubmitting}>
            Annuler
          </Button>

          <Button disabled={!isValid || isSubmitting} type='primary' htmlType='submit'>
            {submitText} {/* Show spinner if submitting */}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ReportForm;
