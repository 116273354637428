import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IUser } from '../../../../../interfaces/user';
import './Contact.scss';
import api from '../../../../../api';

type OwnProps = {
  className?: string;
  setDoctors: (doctors: IUser[]) => void;
  doctors: IUser[];
};

type Props = IUser & OwnProps;

const Contact = ({ doctors, setDoctors, address, img, firstName, lastName, username, email, className, _id, profileLink }: Props) => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const handleGoToProfile = () => {
    navigate(`../doctor-profile/${_id}`);
  };

  const handleDeleteDoctor = () => {
    MySwal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce médecin?',
      text: 'Cette action ne peut pas être annulée.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`doctor/${_id}`);
          setDoctors(doctors.filter((doctor) => doctor._id !== _id));
          notification.success({
            message: 'Médecin supprimé',
            description: 'Le médecin a été supprimé avec succès.',
            placement: 'topRight',
            duration: 3,
          });
        } catch (error) {
          console.error('Error deleting doctor:', error);
        }
      }
    });
  };

  return (
    <div className={`contact ${className}`}>
      {img && (
        <div className='img-box'>
          <img width='400' src={img as string} height='400' alt='avatar' />
        </div>
      )}

      <div className='info-box'>
        <h5 className='name'>@{username}</h5>

        <h4 className='name'>Dr. {firstName + ' ' + lastName}</h4>

        <p className='role'>{email}</p>

        <p className='address'>{address}</p>

        <div className='button-box'>
          <Button type='primary' onClick={handleGoToProfile}>
            Voir le profil
          </Button>
          <Button onClick={handleDeleteDoctor} style={{ marginLeft: '10px' }} danger>
            <span className='icofont icofont-ui-delete' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
