import React from 'react';

import './Actions.scss';

import Notifications from './Notifications';
import SettingsDropdown from './SettingsDropdown';
import SettingsAdminDropdown from "./SettingsAdminDropdown";

const Actions = () => (
  <div className='actions'>
    {/*<Notifications />*/}

    <SettingsAdminDropdown />
  </div>
);

export default Actions;
