import React, { useEffect, useState } from 'react';
import { Button, Select, Input, Card, notification } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../utils/hasError';
import { IForgotPassword, IPatient } from '../../../../interfaces/patient';
import api from "../../../../api";

const Option = Select.Option;
const { TextArea } = Input;

const handleSubmit = async () => { }

type Props = {
  onSubmit: (forgotPassword: IForgotPassword) => void;
  onCancel: () => void;
  forgotPassword?: IForgotPassword;
  submitText?: string;
};

const defaultSubmitText = 'Mot de passe oublié';
const emptyForgotPassword = {
  email: null,
};

const forgotPasswordScheme = Yup.object({
  email: Yup.string().required('L\'email est requis').email("Email non valide")
});

const ForgotPasswordForm = ({
  submitText = defaultSubmitText,
  forgotPassword = emptyForgotPassword,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    // handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IForgotPassword>({
    validationSchema: forgotPasswordScheme,
    initialValues: forgotPassword,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  const sendMail = async (values: IForgotPassword) => {
    try {
      const response = await api.post(`doctor/forgot-password/`, values);
      console.log(response.data);
      notification.success({
        message: 'Email envoyé',
        description: 'Un email de réinitialisation du mot de passe a été envoyé avec succès.',
        duration: 4,
      });
      onSubmit(values);
      onCancel();
    } catch (error) {
      notification.error({
        message: 'Échec de l\'envoi de l\'email',
        description: 'Une erreur est survenue lors de l\'envoi de l\'email. Veuillez réessayer.',
        duration: 4,
      });
      console.error('Erreur lors de l\'envoi de l\'email:', error);
    }
  };

  const hasError = hasErrorFactory(touched, errors);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid) return;
    sendMail(values).then(response => {
      console.log("Envoyer email");
    });
  };

  useEffect(() => {
    setValues(forgotPassword);
  }, [forgotPassword]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">

        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <Input
            id='email'
            placeholder='Email'
            name='email'
            type='email'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            className={hasError('email')}
          />
          <p style={{textAlign:"right"}}>Entrez un email valide</p>
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
