import React, { useState, useEffect } from 'react';
import { Avatar, Card, Form, Select } from 'antd';
import { usePageData } from '../../../../../hooks/usePage';
import { IPageData } from '../../../../../interfaces/page';
import { IUser } from '../../../../../interfaces/user';
import { useParams } from 'react-router-dom';
import api from '../../../../../api';
import PatientsByDoctorPage from "../patientsByDoctor/PatientsByDoctor";
import "./DoctorProfile.scss"
import profile from "../../../../../assets/img/default-user.png"

const pageData: IPageData = {
  title: 'Page de profil du médecin',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Tableau de bord',
      route: '../admin/default-dashboard'
    },
    {
      title: 'Docteurs',
      route: '../admin/doctors'
    },
    {
      title: 'Dr. '
    }
  ]
};

const FormItem = Form.Item;
const Option = Select.Option;

const DoctorProfile = () => {
    const [doctor, setDoctor] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        username: "",
        address: "",
    });
    const { id } = useParams();

    useEffect(() => {
      const fetchDoctor = async (id: string) => {
        try {
          const response = await api.get('doctor/' + id);
          setDoctor(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchDoctor(id);
    }, [id]);

    usePageData(pageData);

    return (
      doctor && doctor.firstName ? (
        <>
          <div className='row'>
              <div className='profile-container'>
                  <Card className='col-md-6 col-sm-11 profile-card'>
                      <Avatar
                          className='profile-avatar'
                          src={profile}
                      />
                      <div className='profile-info'>
                          <div className='profile-item username'>
                              <span className='profile-label'>@ {doctor.username}</span>
                          </div>
                          <div className='profile-item'>
                              <span className='profile-label'>Nom:</span>
                              <span className='profile-value'>{doctor.firstName} {doctor.lastName}</span>
                          </div>
                          <div className='profile-item'>
                              <span className='profile-label'>Email:</span>
                              <span className='profile-value'>{doctor.email}</span>
                          </div>
                          <div className='profile-item'>
                              <span className='profile-label'>Téléphone:</span>
                              <span className='profile-value'>{doctor.phone}</span>
                          </div>
                          <div className='profile-item'>
                              <span className='profile-label'>Adresse:</span>
                              <span className='profile-value'>{doctor.address}</span>
                          </div>
                      </div>
                  </Card>
              </div>
          </div>
          
          <div className='patients-graph'>
            <Card title='Patients' className='mb-0'>
                <PatientsByDoctorPage/>
            </Card>
          </div>
        </>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
            <h1>Médecin introuvable</h1>
        </div>
      )
    );
};

export default DoctorProfile;
