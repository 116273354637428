import React, { ReactNode, useState } from 'react';
import { Input, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { IAppointment } from '../../../../interfaces/patient';

const columns: ColumnProps<IAppointment>[] = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Titre',
    sorter: (a, b) => (a.title > b.title ? 1 : -1),
    render: (title) => (
      <strong>
        {title}
      </strong>
    ),
  },
  {
    key: 'patient',
    dataIndex: 'patient',
    title: 'Patient',
    sorter: (a, b) =>
      a.patient.firstName + " " + a.patient.lastName >
      b.patient.firstName + " " + b.patient.lastName
        ? 1
        : -1,
    render: (patient) => (
      <strong className='nowrap'>
        {patient?.firstName + ' ' + patient?.lastName}
      </strong>
    ),
  },
  {
    key: 'date',
    dataIndex: 'date',
    title: 'Date',
    sorter: (a, b) => 
      new Date(a.date).getTime() - new Date(b.date).getTime(),
    render: (date) => {
  
      if (!date) return null;
  
      const isoDate = date.split('T')[0];
  
      const [year, month, day] = isoDate.split('-');
  
      const formattedDate = `${day}/${month}/${year}`;
  
      return (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {formattedDate}
        </span>
      );
    }
  },
  
  
  
  
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Statut',
    sorter: (a, b) => (a.status > b.status ? 1 : -1),
    render: (status) => (
      <strong
        style={
          status === 'MANQUÉ' || status === 'ANNULÉ'
            ? { color: '#ff4141' }
            : status === 'PRÉSENT'
            ? { color: '#00a81c' }
            : { color: '#3438ff' }
        }
      >
        {status === 'CRÉÉ'
          ? 'CRÉÉ'
          : status === 'MANQUÉ'
          ? 'MANQUÉ'
          : status === 'ANNULÉ'
          ? 'ANNULÉ'
          : status === 'PRÉSENT'
          ? 'PRÉSENT'
          : status}
      </strong>
    ),
  },
];

type Props = {
  data: IAppointment[];
  actions?: (appointment: IAppointment) => ReactNode;
};

const AdminAppointmentsTable = ({ data, actions }: Props) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = data
    .filter(appointment => appointment.patient)
    .filter(appointment =>
      appointment.date.includes(searchText) ||
      appointment.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
      appointment.status.toLowerCase().includes(searchText.toLowerCase()) ||
      (appointment.patient.firstName + " " + appointment.patient.lastName).toLowerCase().includes(searchText.toLowerCase())
    );

  const actionColumn: ColumnProps<IAppointment> = {
    key: 'actions',
    title: 'Actions',
    render: actions,
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  return (
    <>
      <label className="search-label">Rechercher</label>
      <Input
        className="search-input"
        placeholder="Recherche par titre, par statut, par patient ou par date: aaaa-mm-jj"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
      />
      <Table
        rowKey='_id'
        dataSource={filteredData}
        columns={displayedColumns}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};

export default AdminAppointmentsTable;
