import React, { useState } from 'react';
import { Button, Input, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './ConfirmPassword.scss';
import { usePageData } from '../../../../../hooks/usePage';
import { IPageData } from '../../../../../interfaces/page';
import { IUserConfirmPassword } from '../../../../../interfaces/user';
import api from '../../../../../api';
import { audiologistToken, decodedToken } from "../../../../../token/doctor-token";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../utils/hasError';

const DoctorConfirmPassword: React.FC = () => {
    const [passwordErrors, setPasswordErrors] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [passwordVisibility, setPasswordVisibility] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });
    let userId = audiologistToken ? decodedToken.sub : "";
    const defaultSubmitText = 'Mot de passe oublié';
    const emptyConfirmPassword = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    const confirmPasswordScheme = Yup.object({
        currentPassword: Yup.string().required('Mot de passe actuel est requis'),
        newPassword: Yup.string()
            .required('Nouveau mot de passe est requis')
            .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
            .matches(/(?=.*[0-9])/, 'Le mot de passe doit contenir au moins un chiffre')
            .matches(/(?=.*[a-zA-Z])/, 'Le mot de passe doit contenir au moins une lettre'),
        confirmPassword: Yup.string()
            .required('La confirmation du mot de passe est requise')
            .oneOf([Yup.ref('newPassword')], 'Les mots de passe doivent correspondre')
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        resetForm,
        touched,
        values,
        errors,
        isValid
    } = useFormik<IUserConfirmPassword>({
        validationSchema: confirmPasswordScheme,
        initialValues: emptyConfirmPassword,
        onSubmit: (values) => {
            handleConfirmPassword(values.currentPassword, values.newPassword);
        }
    });

    const hasError = hasErrorFactory(touched, errors);

    const handleConfirmPassword = async (password: string, newPassword: string) => {
        try {
            console.log(userId + "  " + password);
            const response = await api.post('doctor/check-password', {
                id: userId,
                password: password
            });

            if (response.data.isMatch) {
                await api.post('doctor/update-password', {
                    id: userId,
                    password: newPassword
                }).then(response => {
                    setPasswordErrors({ currentPassword: "", newPassword: "", confirmPassword: "" });
                    notification.success({
                        message: 'Succès',
                        description: 'Le mot de passe a été mis à jour avec succès.',
                        duration: 4,
                    });
                    console.log(response.data.message);
                    resetForm();
                }).catch((err: any) => {
                    console.log(err.response.data);
                });
            } else {
                setPasswordErrors({ currentPassword: "Vérifiez votre mot de passe actuel", newPassword: "", confirmPassword: "" });
            }
        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message) {
                setPasswordErrors(prevErrors => ({ ...prevErrors, currentPassword: error.response.data.message }));
            }
        }
    };

    const togglePasswordVisibility = (field: keyof typeof passwordVisibility) => {
        setPasswordVisibility(prevState => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const pageData: IPageData = {
        title: 'Changer Votre mot de passe',
        fulFilled: true,
        breadcrumbs: [
            {
                title: 'Changer Votre mot de passe',
            },
        ],
    };
    usePageData(pageData);

    return (
        <>
            <form onSubmit={handleSubmit} className="form-container">
                <div className='form-group'>
                    <label htmlFor='currentPassword'>Mot de passe actuel</label>
                    <Input.Password
                        id='currentPassword'
                        placeholder='Mot de passe actuel'
                        name='currentPassword'
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        style={passwordErrors.currentPassword ? { borderColor: '#ed5564' } : {}}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.currentPassword}
                        className={hasError('currentPassword')}
                        visibilityToggle={true}
                        onClick={() => togglePasswordVisibility('currentPassword')}
                    />
                    {passwordErrors.currentPassword && <p style={{ textAlign: "right", color: "#ed5564" }}>{passwordErrors.currentPassword}</p>}
                </div>

                <div className='form-group'>
                    <label htmlFor='newPassword'>Nouveau mot de passe</label>
                    <Input.Password
                        id='newPassword'
                        placeholder='Nouveau mot de passe'
                        name='newPassword'
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        style={passwordErrors.newPassword ? { borderColor: '#ed5564' } : {}}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        className={hasError('newPassword')}
                        visibilityToggle={true}
                        onClick={() => togglePasswordVisibility('newPassword')}
                    />
                    {touched.newPassword && errors.newPassword && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.newPassword}</p>}
                </div>

                <div className='form-group'>
                    <label htmlFor='confirmPassword'>Confirmer le mot de passe</label>
                    <Input.Password
                        id='confirmPassword'
                        placeholder='Confirmer le mot de passe'
                        name='confirmPassword'
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        style={passwordErrors.confirmPassword ? { borderColor: '#ed5564' } : {}}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        className={hasError('confirmPassword')}
                        visibilityToggle={true}
                        onClick={() => togglePasswordVisibility('confirmPassword')}
                    />
                    {touched.confirmPassword && errors.confirmPassword && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.confirmPassword}</p>}
                </div>

                <Button disabled={!isValid} type='primary' htmlType='submit'>
                    Changer
                </Button>
            </form>
        </>
    );
};

export default DoctorConfirmPassword;
