import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export function useNavigateHome() {
  const navigate = useNavigate();

  return useCallback(() => {
    setTimeout(() => {
      navigate('/admin/default-dashboard');
      window.location.reload();
    }, 100);
  }, []);
}
