import React, {useEffect, useState} from 'react';
import { Button, Card, Modal } from 'antd';
import { format } from 'date-fns';

import { IPageData } from '../../../../../../../interfaces/page';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { usePageData } from '../../../../../../../hooks/usePage';
import {NavLink, useParams} from "react-router-dom";
import {useAdminFetchPageData} from "../../../../../../../hooks/useAdminPage";
import {IAppointment, IPatient} from "../../../../../../../interfaces/patient";

const headerOptions = {
  left: 'prev,next today',
  center: 'title',
  right: 'dayGridMonth,dayGridWeek,dayGridDay'
};



const PatientCalendarPage = () => {
  const {id} = useParams()
  const [appointments, setAppointments] = useAdminFetchPageData<IAppointment[]>(
      `appointement/patient/${id}`,
      []
  );
  const [patient, setPatient] = useAdminFetchPageData<IPatient>(`patient/${id}`,{
    address: "",
    email: "",
    firstName: "",
    lastName: ""
  })
  const pageData: IPageData = {
    title: 'Calendrier pour les rendez-vous',
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Mes patients',
        route: '../doctor/my-patients'
      },
      {
        title: 'Profile de patient',
        route: `../doctor/patient-details/${id}`
      },
      {
        title: `Calendrier pour M(me). ${patient.lastName}`
      }
    ]
  };
  useEffect(() => {
    console.log("appointments1")
    console.log(appointments)
  }, []);
  usePageData(pageData);
  const [event, setEvent] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);

  const setDate = (day: number, hour: number = 0) => {
    const date = new Date();

    date.setDate(date.getDate() + day);
    date.setHours(date.getHours() + hour);

    return date;
  };

  interface Event {
    _id:string;
    start: Date;
    classNames?:string[];
    color:string;
    title: string;
    time:string;
    end: Date;
  }

  const convertAppointmentsToEvents = (appointments: IAppointment[]): Event[] => {
    return appointments.map(appointment => {
      const startDate = new Date(`${appointment.date.split('T')[0]}T${appointment.time}`);
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 2);

      return {
        _id: appointment._id,
        start: startDate,
        color:appointment.status==="MISSED"?'#f56565'
            :appointment.status==="ATTENDANT"?'#42f174'
                :'#4299e1',
        classNames:appointment.status==="MISSED"?['event-error']
            :appointment.status==="ATTENDANT"?['event-green']
                :[],
        time:appointment.time,
        title: appointment.status,
        end: endDate,
      };
    });
  };
  // useEffect(() => {
  // }, []);
  const appointmentEvents = convertAppointmentsToEvents(appointments)

  const closeModal = () => setModalVisibility(false);

  const handleEventClick = (arg: any) => {
    setEvent(arg.event);
    setModalVisibility(true);
  };

  let modalBody, modalTitle, modalFooter;

  const formatDate = (date: Date): string => {
    return format(date, 'dd MMM yyyy');
  };
  const formatTime = (date: Date): string => {
    return format(date, 'HH:mm');
  };

  if (event) {
    modalBody = (
      <div className='d-flex flex-column'>
        <div className='event-time flex-column mb-4'>
          <h5 className='event-title m-0'>Date:</h5>
          <span>
            <strong>{formatDate(event.start)}</strong>
          </span>
          <br/>
          <span>
            <h5>De:</h5> {formatTime(event.start)} <h5>à:</h5> {formatTime(event.end)}
          </span>
        </div>
      </div>
    );

    modalTitle = (
      <div className='title-block p-0 m-0'>
        <h3 style={{ color: event.backgroundColor }} className='modal-title m-0'>
          {event.title}
        </h3>
      </div>
    );

    modalFooter = (
      <div className='d-flex justify-content-between modal-footer'>
        <Button onClick={closeModal} danger>
          Annuler
        </Button>
        {/*<Button type='primary'>Change event</Button>*/}
      </div>
    );
  }

  return (
    <>
      <NavLink style={{fontSize:"20px", fontWeight:"700"}} to={"../../doctor/patient-details/"+id}>Retour</NavLink>
      <Card className='mb-0'>
        <FullCalendar
          eventClick={handleEventClick}
          events={appointmentEvents}
          headerToolbar={headerOptions}
          initialView='dayGridMonth'
          plugins={[dayGridPlugin]}
          dayMaxEvents={true}
          weekends
        />
      </Card>

      <Modal
        title={modalTitle}
        footer={modalFooter}
        open={modalVisibility}
        onCancel={closeModal}
      >
        {modalBody}
      </Modal>
    </>
  );
};

export default PatientCalendarPage;
