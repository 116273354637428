import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Table, Button, Modal, Input, notification } from 'antd';
import { IPatient } from '../../../../../interfaces/patient';
import PatientEditForm from "../editPatient/PatientEditForm";
import api from "../../../../../api";

type Props = {
    patients: IPatient[];
    onEditPatient?: (patient: IPatient) => void;
    onDeletePatient?: (id: string) => void;
    setPatients: (patients: IPatient[]) => void;
};

const PatientsTable = ({
                           patients,
                           onEditPatient = () => null,
                           onDeletePatient = () => null,
                           setPatients
                       }: Props) => {
    const navigate = useNavigate();
    const [patient, setPatient] = useState<IPatient | null>(null);
    const [visibility, setVisibility] = useState(false);
    const [searchText, setSearchText] = useState('');
    const MySwal = withReactContent(Swal);

    const closeModal = () => {
        setPatient(null);
        setVisibility(false);
    };

    const handleShowInfo = (id: string) => {
        console.log(id);
        // navigate(`/patient-appointments/${id}`)
    };

    const handleDeletePatient = (patient: IPatient) => {
        MySwal.fire({
            title: 'Êtes-vous sûr de vouloir supprimer ce patient ?',
            text: 'Cette action est irréversible.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`appointement/patient/${patient._id}`);
                    await api.delete(`patient/${patient._id}`);
                    setPatients(patients.filter(p => p._id !== patient._id));
                    notification.success({
                        message: 'Patient Supprimé',
                        description: `Le patient ${patient.firstName} ${patient.lastName} a été supprimé.`,
                        duration: 4,
                    });
                } catch (error) {
                    notification.error({
                        message: 'Échec de la suppression',
                        description: 'Une erreur est survenue lors de la suppression du patient. Veuillez réessayer.',
                        duration: 4,
                    });
                    console.error('Erreur lors de la suppression du patient:', error);
                }
            }
        });
    };

    const handleEditPatient = (patient: IPatient) => {
        console.log(patient);
        setPatient(patient);
        setTimeout(() => {
            setVisibility(true);
        }, 200);
    };

    const actions = (patient: IPatient) => (
        <div className='buttons-list nowrap'>
            <Link to={`/admin/patient-details/${patient._id}`}>
                <Button shape='circle' onClick={handleShowInfo.bind({}, patient._id)}>
                    <span className='icofont icofont-external-link' />
                </Button>
            </Link>
            <Button onClick={handleEditPatient.bind({}, patient)} shape='circle' type='primary'>
                <span className='icofont icofont-edit-alt' />
            </Button>
            <Button onClick={handleDeletePatient.bind({}, patient)} shape='circle' danger>
                <span className='icofont icofont-ui-delete' />
            </Button>
        </div>
    );

    const columns: ColumnProps<IPatient>[] = [
        {
            key: 'fullName',
            title: 'Nom Complet',
            sorter: (a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName),
            render: (patient: IPatient) => <strong>{`${patient.firstName} ${patient.lastName}`}</strong>
        },
        {
            key: 'doctor',
            dataIndex: 'doctor',
            title: 'Médecin',
            sorter: (a, b) => (a.doctor.username > b.doctor.username ? 1 : -1),
            render: (doctor) => <strong style={!doctor ? { color: "red" } : {}}>{doctor ? doctor.username : "non attribué"}</strong>
        },
        {
          key: 'age',
          dataIndex:'age',
          title: 'Age',
          sorter: (a, b) => a.age > b.age ? 1 : -1,
          render: (age: string) => <strong>{age && age}</strong>
        },
        {
          key: 'sexe',
          dataIndex:'sexe',
          title: 'Sexe',
          sorter: (a, b) => a.sexe > b.sexe ? 1 : -1,
          render: (sexe: string) => <strong>{sexe && sexe}</strong>
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: 'Email',
            sorter: (a, b) => (a.email > b.email ? 1 : -1),
            render: (email) => <strong style={!email ? { color: "red" } : {}}>{email ? email : "non ajouté"}</strong>
        },
        {
            key: 'phone',
            dataIndex: 'phone',
            title: 'Téléphone',
            render: (phone) => (
                <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          +216 {phone}
        </span>
            )
        },
        {
            key: 'actions',
            title: 'Actions',
            render: actions
        }
    ];

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const filteredPatients = patients.filter(patient =>
        (patient.email && patient.email.toLowerCase().includes(searchText.toLowerCase())) ||
        (patient.firstName && patient.lastName &&(patient.firstName + ' ' + patient.lastName).toLowerCase().includes(searchText.toLowerCase())) ||
        (patient.doctor && patient.doctor.username.toLowerCase().includes(searchText.toLowerCase()))
    );

    const pagination = filteredPatients.length <= 10 ? false : {};

    return (
        <>
            <label className="search-label">Recherche</label>
            <Input
                className="col-md-6 col-sm-12 search-input"
                placeholder="Rechercher par email, nom complet ou médecin"
                value={searchText}
                onChange={handleSearch}
                style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
            />
            <Table
                pagination={pagination}
                className='accent-header'
                rowKey='_id'  // Set rowKey to the unique identifier
                dataSource={filteredPatients}
                columns={columns}
            />

            <Modal
                open={visibility}
                footer={null}
                onCancel={closeModal}
                title={
                    <h3 className='title'>Le Patient {patient && patient.firstName}</h3>
                }
            >
                <PatientEditForm
                    submitText='Mettre à jour le patient'
                    onCancel={closeModal}
                    onSubmit={onEditPatient}
                    patient={patient}
                />
            </Modal>
        </>
    );
};

export default PatientsTable;
