import React, { useEffect, useState } from 'react';
import { Modal, Input } from 'antd';
import className from '../../../../utils/class-names';
import Contact from './card/Contact';
import PageAction from '../../../../layout/components/page-action/PageAction';
import DoctorForm from './DoctorForm';
import { usePageData } from '../../../../hooks/usePage';
import { IUser } from '../../../../interfaces/user';
import { IPageData } from '../../../../interfaces/page';
import api from '../../../../api';

const pageData: IPageData = {
  title: 'Médecins',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Tableau de bord',
      route: '../admin/default-dashboard'
    },
    {
      title: 'Docteurs'
    }
  ]
};

const DoctorsPage = () => {
  const [doctorsList, setDoctorsList] = useState<IUser[]>([]);
  const [searchText, setSearchText] = useState('');
  const [addingDoctor, setAddingDoctor] = useState(false);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await api.get('doctor');
        setDoctorsList(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDoctors();
  }, []);

  usePageData(pageData);

  const openModal = () => setAddingDoctor(true);
  const closeModal = () => setAddingDoctor(false);

  const addDoctor = (doctor: IUser) => {
    window.location.reload();
  };

  const getClass = (index: number, length: number) =>
    className({
      'mb-0': index === length - 1,
      'mb-md-0': index === length - 2 || index === length - 3
    });

  const onSetDoctors = (updatedDoctors: IUser[]) => {
    setDoctorsList(updatedDoctors);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredDoctors = doctorsList.filter(doctor =>
    doctor.username.toLowerCase().includes(searchText.toLowerCase()) ||
    doctor.email.toLowerCase().includes(searchText.toLowerCase()) ||
    (doctor.firstName?.toLowerCase() + " " + doctor.lastName?.toLowerCase()).includes(searchText.toLowerCase())
  );

  return (
    <div className='doctors-page'>
      <label className="search-label">Rechercher</label>
      <Input
        className="col-md-6 col-sm-12 search-input"
        placeholder="Rechercher par nom d'utilisateur, email ou nom complet"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize:"17px", border:"1px solid #ccc" }}
      />

      <div className='row'>
        {filteredDoctors && filteredDoctors.map((doctor, index) => (
          <div key={doctor._id} className='col-sm-12 col-md-6 col-lg-4'>
            <Contact className={getClass(index, filteredDoctors.length)} doctors={filteredDoctors} setDoctors={onSetDoctors} {...doctor} />
          </div>
        ))}

        <PageAction onClick={openModal} icon='icofont-contact-add' />

        <Modal
          open={addingDoctor}
          onCancel={closeModal}
          title={<h3 className='title'>Ajouter un médecin</h3>}
          destroyOnClose
          footer={null}
        >
          <DoctorForm onSubmit={addDoctor} onCancel={closeModal} />
        </Modal>
      </div>
    </div>
  );
};

export default DoctorsPage;
