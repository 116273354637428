import React, { useEffect, useState } from 'react';
import { Button, Select, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../utils/hasError';
import { IPatient } from '../../../../../interfaces/patient';
import { selectOptionFilter } from '../../../../../hooks/selectOptionFilter';
import api from '../../../../../api';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const defaultSubmitText = 'Ajouter patient';
const emptyPatient = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  doctor: '',
  phone: '',
  status: '',
  age: '',
  sexe: '',
  number: '',
  gender: '',
  img: ''
};

const patientScheme = Yup.object({
  firstName: Yup.string().required('Le prénom est requis'),
  lastName: Yup.string().required('Le nom est requis'),
  doctor: Yup.string().required('Le médecin est requis'),
  phone: Yup.string().required('Le numéro de téléphone est requis'),
  address: Yup.string().required('L\'adresse est requise'),
  email: Yup.string().email("Ceci n'est pas un email valide"),
  age: Yup.string().required('L\'âge est requis'),
  sexe: Yup.string().required('Le sexe est requis'),
});

const PatientForm = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      // onSubmit(values);
    }
  });

  const [error, setError] = useState("");

  const addPatient = async (values: IPatient) => {
    try {
      const { email, ...otherValues } = values;
      const payload = email ? { ...otherValues, email } : { ...otherValues };

      console.log(payload);

      const response = await api.post('patient', payload);

      if (response && response.data) {
        console.log(response.data);
        setError("");
        onSubmit(values);
        window.location.reload();
        onCancel();
      } else {
        console.log(response.data);
      }
    } catch (error: any) {
      console.error(error);
      setError(error.response.data.message);
    }
  };

  const handleDoctorSelect = (value: string) => setFieldValue('doctor', value);

  const hasError = hasErrorFactory(touched, errors);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) return;
    addPatient(values).then(response => {

    });
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const [doctors, setDoctorsList] = useState([]);
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await api.get('doctor');
        const sortedDoctors = response.data.sort((a: any, b: any) =>
          a.username.localeCompare(b.username)
        );
        setDoctorsList(sortedDoctors);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDoctors();
  }, []);

  const handleGenderSelect = (value: string) => setFieldValue('sexe', value);

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <div className='form-group'>
          <label htmlFor='firstName'>Prénom</label>
          <Input
            id='firstName'
            placeholder='Prénom'
            name='firstName'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.firstName}
            className={hasError('firstName')}
          />
          {touched.firstName && errors.firstName && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.firstName)}
            </div>
          )}
        </div>

        <div className='form-group'>
          <label htmlFor='lastName'>Nom</label>
          <Input
            id='lastName'
            placeholder='Nom'
            name='lastName'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.lastName}
            className={hasError('lastName')}
          />
          {touched.lastName && errors.lastName && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.lastName)}
            </div>
          )}
        </div>

        <div className='form-group'>
          <label htmlFor='doctor'>Docteur</label>
          <Select
            id='doctor'
            showSearch
            placeholder='Sélectionnez le médecin'
            optionFilterProp='children'
            filterOption={selectOptionFilter}
            onChange={handleDoctorSelect}
            onBlur={() => setFieldTouched('doctor')}
            className={hasError('doctor')}
            value={values.doctor}
          >
            {doctors && doctors.map((doctor: any) => (
              <Option value={doctor._id} key={doctor._id}>{doctor.username}</Option>
            ))}
          </Select>
          {touched.doctor && errors.doctor && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.doctor)}
            </div>
          )}
        </div>

        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <Input
            id='email'
            placeholder='Email'
            name='email'
            type='email'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.email}
            className={hasError('email')}
          />
          {touched.email && errors.email && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.email)}
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
            <label htmlFor="age">Age</label>
              <Input
                name='age'
                type='number'
                placeholder='Age'
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.age}
                className={hasError('age')}
              />
              {touched.age && errors.age && (
                <div className="error-message" style={{ color: 'red' }}>
                  <br />
                  {String(errors.age)}
                </div>
              )}
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor="sexe">Sexe</label>
              <Select
                id='sexe'
                placeholder='Sexe'
                defaultValue={values.gender}
                onChange={handleGenderSelect}
                className={hasError('gender')}
                onBlur={() => setFieldTouched('sexe')}
              >
                <Select.Option value='homme' style={{ color: "blue" }}>Homme</Select.Option>
                <Select.Option value='femme' style={{ color: "tomato" }}>Femme</Select.Option>
              </Select>
              {touched.sexe && errors.sexe && (
                <div className="error-message" style={{ color: 'red' }}>
                  <br />
                  {String(errors.sexe)}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label htmlFor='phone'>Téléphone</label>
          <Input
            id='phone'
            placeholder='Téléphone'
            name='phone'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.phone}
            className={hasError('phone')}
          />
          {touched.phone && errors.phone && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.phone)}
            </div>
          )}
        </div>

        <div className='form-group'>
          <label htmlFor='address'>Adresse</label>
          <TextArea
            id='address'
            rows={3}
            name='address'
            placeholder='Adresse'
            onBlur={handleBlur}
            onChange={handleChange}
            defaultValue={values.address}
            className={hasError('address')}
          />
          {touched.address && errors.address && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.address)}
            </div>
          )}
        </div>

        {error && (
          <div className="error-message" style={{ color: 'red', textAlign: "center", margin: "15px" }}>
            {error}
            <br />
          </div>
        )}

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default PatientForm;
