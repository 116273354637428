import React, { useState, ReactNode } from 'react';
import { Table, Input } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IAppointment, IControlPatient, IPatient } from '../../../../../../interfaces/patient';

// Helper function to format dates as jj/mm/aaaa
const formatDate = (date: string) => {
  if (!date) return '';
  const [year, month, day] = date.split('T')[0].split('-');
  return `${day}/${month}/${year}`;
};

const columns: ColumnProps<IControlPatient>[] = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Nom',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
    render: (name) => (
      <strong>
        {name}
      </strong>
    ),
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
    sorter: (a, b) => (a.status > b.status ? 1 : -1),
    render: (status) => (
      <strong
        style={
          status === 'BON'
            ? { color: 'green' }
            : status === 'MOYEN'
            ? { color: 'blue' }
            : { color: 'red' }
        }
      >
        {status}
      </strong>
    )
  },
  {
    key: 'date',
    dataIndex: 'date',
    title: 'Date',
    sorter: (a, b) => {
      const dateTimeA = new Date(a.date.split('T')[0]).getTime();
      const dateTimeB = new Date(b.date.split('T')[0]).getTime();
      return dateTimeA - dateTimeB;
    },
    render: (date) => (
      <span className='nowrap' style={{ color: '#a5a5a5' }}>
        {formatDate(date)} {/* Render the date in jj/mm/aaaa format */}
      </span>
    )
  },
  
  {
    key: 'description',
    dataIndex: 'description',
    title: 'Déscription',
    sorter: (a, b) => (a.description > b.description ? 1 : -1),
    render: (description) => (
      <span>
        {description}
      </span>
    ),
  },
];

type Props = {
  patient: IPatient;
  data: IControlPatient[];
  actions?: (appointment: IControlPatient) => ReactNode;
};

const AdminControlPatientTable = ({ patient, data, actions }: Props) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter((control) => {
    const formattedDate = formatDate(control.date);
    return (
      formattedDate.includes(searchText) || // Match dates in jj/mm/aaaa format
      control.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      control.status.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const actionColumn: ColumnProps<IControlPatient> = {
    key: 'actions',
    title: 'Actions',
    render: actions
  };

  const displayedColumns = actions ? [...columns, actionColumn] : columns;

  return (
    <>
      <label className="search-label">Rechercher</label>
      <Input
        className="search-input"
        placeholder="Recherche par nom, par statut, ou par date: jj/mm/aaaa"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
      />
      <Table
        rowKey='_id'
        dataSource={filteredData}
        columns={displayedColumns}
        pagination={{ hideOnSinglePage: true }}
      />
    </>
  );
};

export default AdminControlPatientTable;
