import React, { useState, useEffect } from 'react';
import { usePageData } from '../../../../../hooks/usePage';
import { usePatients } from '../../../../../hooks/usePatients';
import PatientsTable from './PatientsTable';
import { IPageData } from '../../../../../interfaces/page';
import { IPatient } from '../../../../../interfaces/patient';
import api from "../../../../../api";

const pageData: IPageData = {
  title: 'Des patients',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Tableau de bord',
      route: '/admin/default-dashboard'
    },
    {
      title: 'Les patients'
    }
  ]
};

const PatientsPage = () => {
  const { patients, editPatient, deletePatient } = usePatients();
  usePageData(pageData);

  const [patientsState, setPatientsState] = useState<IPatient[]>(patients);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get('patient');
        setPatientsState(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };
    fetchPatients();
  }, []);

  const handleSetPatients = (updatedPatients: IPatient[]) => {
    setPatientsState(updatedPatients);
  };

  const handleDeletePatient = async (id: string) => {
    try {
      await api.delete(`patient/${id}`);
      setPatientsState(patientsState.filter(patient => patient._id !== id));
    } catch (error) {
      console.error('Error deleting patient:', error);
    }
  };

  return (
      <>
        <PatientsTable
            onDeletePatient={handleDeletePatient}
            onEditPatient={editPatient}
            patients={patientsState}
            setPatients={handleSetPatients}
        />
      </>
  );
};

export default PatientsPage;
