import React from 'react';

import { Modal } from 'antd';
import {IAppointment, IReport} from '../../../../../../interfaces/patient';
import ReportEditForm from './reportEditForm/ReportEditForm';

type Props = {
  report: IReport;
  patientId?: string;
  onSubmit: (report: IReport) => void;
  visible: boolean;
  onClose: () => void;
};

const EditReport = ({report, patientId, visible, onClose, onSubmit }: Props) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={<h3 className='title'>Modifier le rapport</h3>}
    >
      <ReportEditForm patientId={patientId} report={report} onCancel={onClose} onSubmit={onSubmit} submitText='Modifier le rapport' />
    </Modal>
  );
};

export default EditReport;
