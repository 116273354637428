import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Input, notification, Divider } from 'antd';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../utils/hasError';
import { IUser } from '../../../../interfaces/user';
import api from '../../../../api';

const { TextArea } = Input;

type Props = {
  onSubmit: (doctor: IUser) => void;
  onCancel: () => void;
};

const doctorScheme = Yup.object({
  username: Yup.string()
    .required('Le nom d\'utilisateur est requis')
    .matches(/\S/, 'Le nom d\'utilisateur ne peut pas être vide ou seulement des espaces'),
  firstName: Yup.string().required('Le prénom est requis'),
  lastName: Yup.string().required('Le nom est requis'),
  phone: Yup.string().required('Le numéro de téléphone est requis'),
  email: Yup.string().required('L\'email est requis').email('Ceci n\'est pas un email valide!'),
  address: Yup.string().required('L\'adresse est requise'),
});

const initialValues = {
  role: null,
  firstName: null,
  username: null,
  lastName: null,
  email: null,
  phone: null,
  img: null,
  gender: null,
  address: null,
  social: [
    {
      icon: 'icofont-instagram',
      link: '#',
    },
    {
      icon: 'icofont-facebook',
      link: '#',
    },
    {
      icon: 'icofont-twitter',
      link: '#',
    },
  ],
};

const DoctorForm = ({ onSubmit, onCancel }: Props) => {
  const [addingUsernameError, setAddingUsernameError] = useState<string>();
  const [addingEmailError, setAddingEmailError] = useState<string>();
  const [addingAllError, setAddingAllError] = useState<string>();

  const {
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: doctorScheme,
    validateOnMount: true,
    initialErrors: { firstName: null },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleCancel = () => {
    console.log('errors');
    console.log(errors);
    onCancel();
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    try {
      const response = await api.post('doctor', values);
      console.log('resp');
      setAddingUsernameError('');
      setAddingEmailError('');
      setAddingAllError('');
      console.log(response.data);

      // Show success notification
      notification.success({
        message: 'Docteur ajouté',
        description: 'Le docteur a été ajouté avec succès.',
        placement: 'topRight',
      });

      onCancel();
      onSubmit(values);
    } catch (error) {
      console.error(error);
      console.log(error['response']['data']['message']);
      switch (error['response']['data']['message']) {
        case 'Username already exists':
          setAddingEmailError('');
          setAddingAllError('');
          setAddingUsernameError('Le nom d\'utilisateur existe déjà');
          break;
        case 'Email already exists':
          setAddingUsernameError('');
          setAddingAllError('');
          setAddingEmailError('L\'email existe déjà');
          break;
        default:
          setAddingUsernameError('');
          setAddingEmailError('');
          setAddingAllError('Le nom d\'utilisateur et l\'email existent déjà');
          break;
      }
    }
  };

  const hasError = hasErrorFactory(touched, errors);

  return (
    <>
      <Form>
        {addingUsernameError && (
          <div className="error-message" style={{ color: 'red' }}>
            {addingUsernameError}
          </div>
        )}
        {addingEmailError && (
          <div className="error-message" style={{ color: 'red' }}>
            {addingEmailError}
          </div>
        )}
        {addingAllError && (
          <div className="error-message" style={{ color: 'red' }}>
            {addingAllError}
          </div>
        )}

        <div className="form-group">
          <Input
            name="username"
            type="text"
            placeholder="Nom d'utilisateur"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.username}
            className={hasError('username')}
          />
          {touched.username && errors.username && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.username)}
            </div>
          )}
        </div>

        <div className="form-group">
          <Input
            name="firstName"
            type="text"
            placeholder="Prénom"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.firstName}
            className={hasError('firstName')}
          />
          {touched.firstName && errors.firstName && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.firstName)}
            </div>
          )}
        </div>

        <div className="form-group">
          <Input
            type="text"
            name="lastName"
            placeholder="Nom"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.lastName}
            className={hasError('lastName')}
          />
          {touched.lastName && errors.lastName && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.lastName)}
            </div>
          )}
        </div>

        <div className="form-group">
          <Input
            type="text"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.email}
            className={hasError('email')}
          />
          {touched.email && errors.email && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.email)}
            </div>
          )}
        </div>

        <div className="form-group">
          <Input
            type="number"
            name="phone"
            placeholder="Téléphone"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.phone}
            className={hasError('phone')}
          />
          {touched.phone && errors.phone && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.phone)}
            </div>
          )}
        </div>

        <TextArea
          rows={3}
          name="address"
          placeholder="Adresse"
          onBlur={handleBlur}
          onChange={handleChange}
          defaultValue={values.address}
          className={hasError('address')}
        />
        {touched.address && errors.address && (
          <div className="error-message" style={{ color: 'red' }}>
            {String(errors.address)}
          </div>
        )}

        <Divider />

        <div className="d-flex justify-content-between buttons-list settings-actions mt-4">
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button
            disabled={!isValid}
            onClick={handleSubmit}
            htmlType="submit"
            type="primary"
          >
            Ajouter le Docteur
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DoctorForm;
