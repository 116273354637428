import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ConfigProvider from 'antd/es/config-provider';
import VerticalLayout from './layout/admin/Admin';
import HorizontalLayout from './layout/horizontal/Horizontal';
import NotFound from './pages/sessions/404';
import { defaultRoutes, sessionRoutes } from './routing';
import { useHideLoader } from './hooks/useHideLoader';
import './App.scss';
import DoctorLayout from './layout/doctor/Doctor';
import { defaultDoctorRoutes } from './routing/default-doctor-routes';
import AdminUnauthorized from './pages/sessions/Unauthorized/AdminUnauthorized';
import {ProtectedDoctorRoute, ProtectedRoute} from './utils/ProtectedRoute';
import DoctorUnauthorized from "./pages/sessions/Unauthorized/DoctorUnauthorized";

const LayoutRoutes = ({ routes, layout = '' }: { routes: any[]; layout?: string }) => (
  <Routes>
    {routes.map((route, index) => (
      <Route key={index} path={`/${route.path}`} element={<route.component />} />
    ))}
    <Route path='*' element={<Navigate replace to='/public/page-404' />} />
  </Routes>
);

const DefaultAdminRoutes = ({ layout }: { layout?: string }) => <LayoutRoutes routes={defaultRoutes} layout={layout} />;
const DefaultDoctorRoutes = ({ layout }: { layout?: string }) => <LayoutRoutes routes={defaultDoctorRoutes} layout={layout} />;
const SessionRoutes = () => <LayoutRoutes routes={sessionRoutes} layout='public' />;

const App = () => {
  useHideLoader();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Lato', sans-serif",
        },
      }}
    >
      <Routes>
        <Route path='/' element={<Navigate replace to='/public/doctor-sign-in' />} />
        <Route path='/public/*' element={<SessionRoutes />} />
        
        <Route
          path='/horizontal/*'
          element={
            <ProtectedRoute element={<HorizontalLayout children={undefined} />} allowedRoles={['admin']}>
              <DefaultAdminRoutes layout='horizontal' />
            </ProtectedRoute>
          }
        />
        <Route
          path='/admin/*'
          element={
            <ProtectedRoute element={<VerticalLayout children={undefined} />} allowedRoles={['admin']}>
              <DefaultAdminRoutes layout='vertical' />
            </ProtectedRoute>
          }
        />
        <Route
          path='/doctor/*'
          element={
            <ProtectedDoctorRoute element={<DoctorLayout children={undefined} />} allowedRoles={['doctor', 'admin']}>
              <DefaultDoctorRoutes layout='vertical' />
            </ProtectedDoctorRoute>
          }
        />
        <Route path='public/unauthorized' element={<AdminUnauthorized />} />
        <Route path='public/non-authorized' element={<DoctorUnauthorized />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </ConfigProvider>
  );
};

export default App;
