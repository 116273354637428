import React, { useState } from 'react';
import {
  Avatar,
  Card,
  Form,
  Modal,
  Select,
  Table,
} from 'antd';
import './DoctorProfile.scss';
import { usePageData } from '../../../../hooks/usePage';
import { IPageData } from '../../../../interfaces/page';
import { IUser } from '../../../../interfaces/user';
import api from '../../../../api';
import { jwtDecode } from "jwt-decode";
import { audiologistToken } from "../../../../token/doctor-token";
import userImage from "../../../../assets/img/default-user.png";
import ActionPage from "../../../../layout/components/page-action/PageAction";
import ModifierProfil from "./EditProfile";

const DoctorCurrentProfile = () => {
  const [editingDoctor, setEditingDoctor] = useState(false);
  const [doctor, setDoctor] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    username: "",
    address: "",
  });
  let id = audiologistToken ? jwtDecode(audiologistToken).sub : "";

  const fetchDoctor = async (id: string) => {
    try {
      const response = await api.get('doctor/' + id).then((response) => {
        setDoctor(response.data);
      }).catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };

  fetchDoctor(id);

  

const pageData: IPageData = {
  title: 'Ma page de profil',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Ma page de profil',
    },
  ],
};
  usePageData(pageData);

  const openModal = () => setEditingDoctor(true);
  const closeModal = () => setEditingDoctor(false);
  const editDoctor = () => {};

  return doctor && (
    <>
      <div className="row">
        <div className="col-md-12 profile-container">
          <Card className="col-md-6 col-sm-12 profile-card">
            <Avatar className="profile-avatar" src={userImage} />
            <div className="profile-info">
              <div className="profile-item username">
                <span className="profile-label">@ {doctor.username}</span>
              </div>
              <div className="profile-item">
                <span className="profile-label">Nom:</span>
                <span className="profile-value">
                  {doctor.firstName} {doctor.lastName}
                </span>
              </div>
              <div className="profile-item">
                <span className="profile-label">Email:</span>
                <span className="profile-value">{doctor.email}</span>
              </div>
              <div className="profile-item">
                <span className="profile-label">Téléphone:</span>
                <span className="profile-value">{doctor.phone}</span>
              </div>
              <div className="profile-item">
                <span className="profile-label">Adresse:</span>
                <span className="profile-value">{doctor.address}</span>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <ActionPage onClick={openModal} icon="icofont-contact-add" />

      <Modal
        open={editingDoctor}
        onCancel={closeModal}
        title={<h3 className="title">Modifier mon profil</h3>}
        destroyOnClose
        footer={null}
      >
        <ModifierProfil doctor={doctor} onSubmit={editDoctor} onCancel={closeModal} />
      </Modal>
    </>
  );
};

export default DoctorCurrentProfile;
