import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useNavigateDoctorsList() {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate('/admin/doctors');
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }, [navigate]);
}

export function useNavigateDoctorCurrentProfile() {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate('/doctor/my-profile');
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }, [navigate]);
}
