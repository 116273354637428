import React, { useEffect } from 'react';
import { Button, DatePicker, TimePicker, Select, Form, DatePickerProps } from 'antd';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../../utils/hasError';
import { IAppointment } from '../../../../../../interfaces/patient';
import api from "../../../../../../api";

type Props = {
  patientId?: string;
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const { Option } = Select;

// Enum status values
const StatusEnum = {
  ATTENDANT: 'PRÉSENT',
  MISSED: 'MANQUÉ',
  CANCELED: 'ANNULÉ',
  PENDING: 'EN ATTENTE',
  CREATED: 'CRÉÉ',
};

const defaultSubmitText = 'Ajouter un rendez-vous';
const emptyAppointment = {
  patientId: "",
  title: 'Essai de prothèse', // Set the default value to the first option
  date: null,
  time: '',
  status: StatusEnum.CREATED, // Default status value
};

const appointmentSchema = Yup.object().shape({
  date: Yup.string().required('La date est requise'),
  title: Yup.string().required('Le titre est requis'),
  time: Yup.string().required('L\'horaire est requise'),
  status: Yup.string().required('Le statut est requis'),
});

const AppointmentForm = ({
  patientId,
  submitText = defaultSubmitText,
  appointment = emptyAppointment,
  onSubmit,
  onCancel
}: Props) => {
  const {
    handleSubmit,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
    resetForm,
    setFieldValue
  } = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: async (form) => {
      console.log({ ...form, status: form.status || StatusEnum.CREATED });
      await api.post("appointement", { ...form, patient: patientId }).then(response => {
        onSubmit({ ...form, status: form.status || StatusEnum.CREATED });
        resetForm();
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    setValues({ ...appointment });
  }, [appointment]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const onDateChange: DatePickerProps['onChange'] = (date) => {
    setFieldValue('date', date ? date.toISOString() : null);
  };

  const hasError = hasErrorFactory(touched, errors);

  const timeFormat = 'HH:mm';

  return (
    <>
      <form onSubmit={handleSubmit}>
        
        <div className="form-group">
          <Select
            placeholder="Titre"
            onChange={(value) => setFieldValue('title', value)}
            onBlur={handleBlur}
            value={values.title}
            className={hasError('title')}
          >
            <Option value="Essai de prothèse">Essai de prothèse</Option>
            <Option value="Tests d'audition">Tests d'audition</Option>
            <Option value="Contrôle d'appareillage">Contrôle d'appareillage</Option>
            <Option value="SAV-diagnostic">SAV-diagnostic</Option>
          </Select>
          {touched.title && errors.title && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.title)}
            </div>
          )}
        </div>

        <Form.Item className='form-group'>
          <DatePicker
            defaultValue={values.date ? dayjs(values.date) : null}
            placeholder='Date'
            onChange={onDateChange}
            className={hasError('date')}
          />
          {touched.date && errors.date && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.date)}
            </div>
          )}
        </Form.Item>

        <Form.Item className="">
          <TimePicker
            defaultValue={values.time ? dayjs(values.time, timeFormat) : null}
            placeholder='Heure'
            format={timeFormat}
            onChange={(time) => setFieldValue('time', time ? dayjs(time).format(timeFormat) : null)}
            className={hasError('time')}
          />
        </Form.Item>

        {/* Status Select Dropdown */}
        <Form.Item className='form-group'>
          <Select
            placeholder="Statut"
            onChange={(value) => setFieldValue('status', value)}
            onBlur={handleBlur}
            value={values.status}
            className={hasError('status')}
          >
            {Object.entries(StatusEnum).map(([key, value]) => (
              <Option key={key} value={value}>
                {value}
              </Option>
            ))}
          </Select>
          {touched.status && errors.status && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.status)}
            </div>
          )}
        </Form.Item>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AppointmentForm;
