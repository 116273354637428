import React from 'react';

import { Modal } from 'antd';
import {IAppointment, IReport} from '../../../../../../interfaces/patient';
import ReportForm from "./reportForm/ReportForm";

type Props = {
  patientId?: string;
  onSubmit: (report: IReport) => void;
  visible: boolean;
  onClose: () => void;
};

const AddAppointment = ({ patientId, visible, onClose, onSubmit }: Props) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={<h3 className='title'>Ajouter un rapport</h3>}
    >
      <ReportForm patientId={patientId} onCancel={onClose} onSubmit={onSubmit} submitText='Ajouter un rapport' />
    </Modal>
  );
};

export default AddAppointment;
