import { IRoute } from '../interfaces/routing';

import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import SignIn from '../pages/roles/admin/authenticate/Sign-in';
import SignInDoctor from '../pages/roles/doctor/authenticate/Sign-in-doctor';
import SignUp from '../pages/sessions/Sign-up';
import AdminUnauthorized from '../pages/sessions/Unauthorized/AdminUnauthorized';

export const sessionRoutes: IRoute[] = [
  {
    path: 'page-404',
    component: NotFound
  },
  {
    path: 'page-500',
    component: InternalError
  },
  {
    path: 'admin-sign-in',
    component: SignIn
  },
  {
    path: 'doctor-sign-in',
    component: SignInDoctor
  },
  {
    path: 'admin-sign-up',
    component: SignUp
  },
  {
    path: 'unauthorized',
    component: AdminUnauthorized
  }
];
