import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { Avatar, Dropdown } from 'antd';
import {urlRole} from "../../../token/route";
import {jwtDecode} from "jwt-decode";
import {audiologistToken} from "../../../token/doctor-token";



const accountItems = [
  // { text: 'Edit account', icon: 'icofont-ui-home', route: '/admin/edit-account' },
  // { text: 'User profile', icon: 'icofont-ui-user', route: '/admin/user-profile' },
  // { text: 'Calendar', icon: 'icofont-ui-calendar', route: '/admin/events-calendar' },
  // { text: 'Settings', icon: 'icofont-ui-settings', route: '/admin/settings' },
  { text: 'Se déconnecter', icon: 'icofont-logout', route: `/public/admin-sign-in` }
];

const SettingsAdminDropdown = () => {
  const navigate = useNavigate()
  const accountMenu = () => (
    <ul className='actions-menu' style={{ minWidth: '180px' }}>
      {accountItems.map((item, index) => (
        <>
          {item.route === `/public/admin-sign-in`?
          <li className='action-item' key={index} onClick={()=>{
            console.log("logging out")
            if(audiologistToken) {
              localStorage.removeItem("audiologist_token")
              setTimeout(() => {
                navigate(item.route)
                // window.location.reload()
              }, 100);
            }
          }}>
            <NavLink to={""} className='d-flex w-100'>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </NavLink>
          </li>
          :
          <li className='action-item' key={index}>
            <NavLink className='d-flex w-100' to={item.route} replace>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </NavLink>
          </li>
      }
        </>
      ))}
    </ul>
  );

  return (
    <Dropdown dropdownRender={accountMenu} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar
          size={40}
          className='mr-1'
          src={`${window.location.origin}/content/default-user.png`}
        />
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsAdminDropdown;
