import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import { useForm } from 'antd/es/form/Form';
import PublicLayout from '../../../../layout/public/Public';
import { useNavigateHome } from '../../../../utils/use-navigate-home';
import { api_url } from '../../../../domain/back';
import axios from 'axios';
import { useNavigateDoctorCurrentProfile } from '../../../../utils/use-navigate-doctors-list';
import { jwtDecode } from "jwt-decode";
import { decodedToken } from "../../../../token/doctor-token";
import { getUserRole, isLoggedIn } from '../../../../utils/auth';
import ForgotPasswordForm from './ForgotPassword';
import { IForgotPassword } from '../../../../interfaces/patient';

const SignInDoctor = () => {
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const navigateDoctorCurrentProfile = useNavigateDoctorCurrentProfile();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  
  useEffect(() => {
    if (isLoggedIn && getUserRole() === "doctor") {
      navigate("/doctor/my-profile");
    }
  }, [navigate]);

  const login = () => {
    form
      .validateFields()
      .then(async () => { 
        const {
          login,
          password
        } = form.getFieldsValue(["login", "password"])
        try {
          const response = await axios.post(api_url + 'auth/doctor/login', {
            username: login,
            password: password
          }).then((response) => {
            const token = response.data.access_token;
            if (token) {
              localStorage.setItem('audiologist_token', token);
              setTimeout(() => {
                console.log(decodedToken);
                navigateDoctorCurrentProfile();
              }, 200);
            } else if (response.data.response) {
              if (response.data.response.message) {
                setError(true);
                setErrorMessage(response.data.response.message);
              } else {
                setError(true);
                setErrorMessage(response.data.response);
              }
            }
          }).catch(err => {
            console.log(err);
          });
        } catch (error) {
          console.error('Login failed:', error);
          // Handle error (e.g., show a notification)
        }
      })
      .catch(() => null);
  };

  

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleForgotPassword = () => {
    closeModal();
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
      <h4 className='mt-0 mb-1'>Bienvenue Docteur!</h4>

      <p className='text-color-200'>Connectez-vous pour accéder à votre compte</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Form.Item
          name='login'
          rules={[{ required: true, message: "Le nom d'utilisateur est requis" }]}
          validateStatus={error ? 'error' : ''}
        >
          <Input placeholder="Nom d'utilisateur" style={error ? { borderColor: '#ed5564' } : {}} />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Le mot de passe est requis' }]}
          validateStatus={error ? 'error' : ''}
        >
          <Input placeholder='Mot de passe' type='password' style={error ? { borderColor: '#ed5564' } : {}} />
        </Form.Item>

        <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Se souvenir de moi</span>
        </div>
        {errorMessage && <p style={{ color: '#ed5564', marginTop: '1rem' }}>{errorMessage}</p>}
        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Connexion
        </Button>
      </Form>
      <br />
      <p className='mb-1'>
        <a href='#' onClick={handleClick}>Mot de passe oublié?</a>
      </p>
      
      <Modal
        open={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Mot de passe oublié</h3>}
      >
        <ForgotPasswordForm onCancel={closeModal} onSubmit={handleForgotPassword} />
      </Modal>
    </PublicLayout>
  );
};

export default SignInDoctor;
