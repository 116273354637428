// src/hocs/withAuthorization.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserRole, isLoggedIn } from '../utils/auth';

const withAuthorization = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  allowedRoles: string[]
) => {
  const WithAuthorization: React.FC<P> = (props) => {
    const role = getUserRole();
    const loggedIn = isLoggedIn();

    if (!loggedIn || !allowedRoles.includes(role!)) {
      return <Navigate to="/public/unauthorized" />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuthorization;
};

export default withAuthorization;
