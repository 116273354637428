// src/pages/sessions/Unauthorized/AdminUnauthorized.tsx
import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import "./Unauthorized.style.scss"
import {Spin} from "antd";

const AdminUnauthorized: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      navigate('/public/admin-sign-in');
    }, 3000);
  }, [navigate]);
  return (
      <div className="unauthorized-container">
        <h1 className="unauthorized-title">Unauthorized Access</h1>
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      </div>
  );
};

export default AdminUnauthorized;
