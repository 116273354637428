import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table'; // Type definition for table columns
import { Avatar, Table, Button, Modal, Tag, Input } from 'antd'; // Ant Design components

import { IPatient } from '../../../../../interfaces/patient'; // Interface definition for patient data

// Props interface for PatientsTable component
type Props = {
  patients: IPatient[]; // List of patient objects
  onEditPatient?: (patient: IPatient) => void; // Optional callback function for editing a patient
  onDeletePatient?: (id: string) => void; // Optional callback function for deleting a patient
};

const PatientsTable = ({ patients }: Props) => {

  const navigate = useNavigate(); // Hook for programmatic navigation

  // Function to handle showing patient details
  const handleShowInfo = (patient: IPatient) => navigate(`/doctor/patient-details/${patient._id}`);

  // Function to define actions for each patient row
  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={handleShowInfo.bind({}, patient)}>
          <span className='icofont icofont-external-link' />
      </Button>
    </div>
  );

  // Define table columns with sorting and rendering functions
  const columns: ColumnProps<IPatient>[] = [
    {
      key: 'fullName',
      title: 'Nom Complet',
      sorter: (a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName),
      render: (patient: IPatient) => <strong>{`${patient.firstName} ${patient.lastName}`}</strong>
    },
    {
      key: 'age',
      dataIndex:'age',
      title: 'Age',
      sorter: (a, b) => a.age > b.age ? 1 : -1,
      render: (age: string) => <strong>{age && age}</strong>
    },
    {
      key: 'sexe',
      dataIndex:'sexe',
      title: 'Sexe',
      sorter: (a, b) => a.sexe > b.sexe ? 1 : -1,
      render: (sexe: string) => <strong>{sexe && sexe}</strong>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render: (email) => (
        <strong style={!email ? { color: "red" } : {}}>{email ? email : "non ajouté"}</strong>
      )
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Téléphone',
      render: (phone) => ( // Renders phone number with icon
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 18 }} />
          +216 {phone}
        </span>
      ),
    },
    {
      key: 'actions',
      title: 'Détails',
      render: actions, // Renders the defined actions function
    },
  ];

  // Set pagination based on the number of patients (optional)
  const pagination = patients.length <= 10 ? false : {};
  const [searchText, setSearchText] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
  };

  const filteredPatients = patients.filter(patient =>
    patient.email && patient.email.toLowerCase().includes(searchText && searchText.toLowerCase()) ||
    (patient.firstName + ' ' + patient.lastName).toLowerCase().includes(searchText && searchText.toLowerCase()) ||
    (patient.doctor && patient.doctor.username?.toLowerCase().includes(searchText && searchText.toLowerCase())) // Use optional chaining here
  );

  return (
    <>
    <label className="search-label">Recherche</label>
    <Input
        className="col-md-6 col-sm-12 search-input"
        placeholder="Rechercher par email, nom complet ou médecin"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
    />
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='_id' // Unique identifier for each row
        dataSource={filteredPatients} // Data source for the table
        columns={columns} // Table columns definition
      />
    </>
  );
};

export default PatientsTable;
