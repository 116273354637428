import React from 'react';

import { Modal } from 'antd';

import AppointmentForm from './ControlPatientForm';
import { IAppointment, IControlPatient } from '../../../../../../interfaces/patient';
import ControlPatientForm from './ControlPatientForm';

type Props = {
  patientId?: string;
  onSubmit: (cp: IControlPatient) => void;
  visible: boolean;
  onClose: () => void;
};

const AddControlPatient = ({ patientId, visible, onClose, onSubmit }: Props) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={<h3 className='title'>Ajouter un nettoyage</h3>}
    >
      <ControlPatientForm patientId={patientId} onCancel={onClose} onSubmit={onSubmit} submitText='Ajouter un netoyage' />
    </Modal>
  );
};

export default AddControlPatient;
