import React, { useEffect, useState } from 'react';
import { Button, Input, notification, Select } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../utils/hasError';
import { IPatient } from '../../../../../interfaces/patient';
import api from "../../../../../api";

const { TextArea } = Input;

type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const defaultSubmitText = 'modifier le patient';
const emptyPatient = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  doctor: '',
  phone: '',
  status: '',
  age: '',
  sexe: '',
  number: '',
  gender: '',
  img: null
};

const patientScheme = Yup.object({
  firstName: Yup.string().required('Le prénom est requis'),
  lastName: Yup.string().required('Le nom de famille est requis'),
  phone: Yup.string().required('Le numéro de téléphone est requis'),
  address: Yup.string().required('L\'adresse est requise'),
  age: Yup.string().required('L\'âge est requis'),
  sexe: Yup.string().required('Le sexe est requis'),
  email: Yup.string().email("Email non valide"),
});

const PatientEditForm = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      editPatient(values);
    }
  });

  const [error, setError] = useState("");

  const editPatient = async (values: IPatient) => {
    try {

      await api.put(`patient/${values._id}`, {...values , doctor: values.doctor._id});

      notification.success({
        message: 'Patient Mis à Jour',
        description: `Le patient ${values.firstName} ${values.lastName} a été mis à jour avec succès.`,
        duration: 4,
      });

      onSubmit(values);
      window.location.reload();
      onCancel();
    } catch (error) {
      notification.error({
        message: 'Échec de la Mise à Jour',
        description: 'Une erreur est survenue lors de la mise à jour du patient. Veuillez réessayer.',
        duration: 4,
      });
      console.error(error);
    }
  };

  const hasError = hasErrorFactory(touched, errors);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) return;
    handleSubmit();
  };

  useEffect(() => {
    setValues(patient);
  }, [patient]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleGenderSelect = (value: string) => setFieldValue('sexe', value);

  return (
    <>
      <form onSubmit={handleFormSubmit} className="form-container">
        <div className='form-group'>
          <label htmlFor='firstName'>Prénom</label>
          <Input
            id='firstName'
            placeholder='Prénom'
            name='firstName'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
            className={hasError('firstName')}
          />
          {touched.firstName && errors.firstName && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.firstName}</p>}
        </div>

        <div className='form-group'>
          <label htmlFor='lastName'>Nom de Famille</label>
          <Input
            id='lastName'
            placeholder='Nom de Famille'
            name='lastName'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastName}
            className={hasError('lastName')}
          />
          {touched.lastName && errors.lastName && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.lastName}</p>}
        </div>
        
        <div className='row'>
          <div className='col-sm-6 col-12'>
            <div className='form-group'>
            <label htmlFor="age">Age</label>
              <Input
                name='age'
                type='number'
                placeholder='Age'
                onBlur={handleBlur}
                onChange={handleChange}
                defaultValue={values.age}
                className={hasError('age')}
              />
              {touched.age && errors.age && (
                <div className="error-message" style={{ color: 'red' }}>
                  <br />
                  {String(errors.age)}
                </div>
              )}
            </div>
          </div>

          <div className='col-sm-6 col-12'>
            <div className='form-group'>
              <label htmlFor="sexe">Sexe</label>
              <Select
                id='sexe'
                placeholder='Sexe'
                defaultValue={values.gender}
                onChange={handleGenderSelect}
                className={hasError('gender')}
                onBlur={() => setFieldTouched('sexe')}
              >
                <Select.Option value='homme' style={{ color: "blue" }}>Homme</Select.Option>
                <Select.Option value='femme' style={{ color: "tomato" }}>Femme</Select.Option>
              </Select>
              {touched.sexe && errors.sexe && (
                <div className="error-message" style={{ color: 'red' }}>
                  <br />
                  {String(errors.sexe)}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <Input
            id='email'
            placeholder='Email'
            name='email'
            type='email'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            className={hasError('email')}
          />
          {touched.email && errors.email && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.email}</p>}
        </div>

        <div className='form-group'>
          <label htmlFor='phone'>Téléphone</label>
          <Input
            id='phone'
            placeholder='Téléphone'
            name='phone'
            type='text'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.phone}
            className={hasError('phone')}
          />
          {touched.phone && errors.phone && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.phone}</p>}
        </div>

        <div className='form-group'>
          <label htmlFor='address'>Adresse</label>
          <TextArea
            id='address'
            rows={3}
            name='address'
            placeholder='Adresse'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.address}
            className={hasError('address')}
          />
          {touched.address && errors.address && <p style={{ textAlign: "right", color: "#ed5564" }}>{errors.address}</p>}
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default PatientEditForm;
