import React from 'react';

import './Actions.scss';
import SettingsDropdown from './SettingsDropdown';

const DoctorActions = () => (
  <div className='actions'>
    {/*<Notifications />*/}

    <SettingsDropdown />
  </div>
);

export default DoctorActions;
