import React, { useState } from 'react';
import { Button, Input, notification, Table, Radio } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IReport, IPatient } from '../../../../../../interfaces/patient';
import { Link } from 'react-router-dom';
import api from '../../../../../../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './reportTable.scss';
import EditReport from './EditReport';

type Props = {
  patient: IPatient;
  data: IReport[];
  setReports?: (reports: IReport[]) => void;
  updateActiveReports: (activeReports: string[]) => void;
};

const AdminReportsByPatientTable = ({
  patient,
  data,
  setReports,
  updateActiveReports,
}: Props) => {
  const [activeReports, setActiveReports] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState('all'); // State for the filter type
  const [report, setReport] = useState<IReport | null>(null);
  const [visibility, setVisibility] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const MySwal = withReactContent(Swal);

  const handleShowInfo = (report: IReport) => {
    console.log(report);
  };

  const handleDeleteReport = (report: IReport) => {
    MySwal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce rapport ?',
      text: 'Cette action est irréversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`report/${report._id}`);
          setReports(data.filter((p) => p._id !== report._id));

          notification.success({
            message: 'Rapport Supprimé',
            description: `Le rapport de ${patient.firstName} ${patient.lastName} a été supprimé avec succès.`,
            duration: 4, // Durée en secondes
          });
        } catch (error) {
          notification.error({
            message: 'Erreur',
            description: 'Une erreur est survenue lors de la suppression du rapport. Veuillez réessayer.',
            duration: 4,
          });
        }
      }
    });
  };

  const handleToggleCompare = (reportId: string) => {
    setActiveReports((prevActiveReports) => {
      let newActiveReports = prevActiveReports;
      if (prevActiveReports.includes(reportId)) {
        newActiveReports = prevActiveReports.filter((id) => id !== reportId);
      } else if (prevActiveReports.length < 2) {
        newActiveReports = [...prevActiveReports, reportId];
      }

      updateActiveReports(newActiveReports);
      return newActiveReports;
    });
  };

  const handleEditReport = (report: IReport) => {
    setReport(report);
    setSelectedReport(report);
    setTimeout(() => {
      setVisibility(true);
    }, 200);
  };

  const actions = (report: IReport) => (
    <div className='buttons-list nowrap'>
      <Link to={`/admin/report/${report._id}?doctor=` + patient.doctor}>
        <Button shape='circle' onClick={() => handleShowInfo(report)}>
          <span className='icofont icofont-external-link' />
        </Button>
      </Link>
      <Button onClick={handleEditReport.bind({}, report)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={() => handleDeleteReport(report)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div>
  );

  const compareButton = (report: IReport) => (
    <Button
      className={`btn-select ${activeReports.includes(report._id) ? 'active' : ''}`}
      onClick={() => handleToggleCompare(report._id)}
      disabled={!activeReports.includes(report._id) && activeReports.length >= 2}
    >
      {activeReports.includes(report._id) ? 'Sélectionné' : 'Sélectionner'}
    </Button>
  );

  const actionColumn: ColumnProps<IReport> = {
    key: 'actions',
    title: 'Actions',
    render: actions,
  };

  const compareColumn: ColumnProps<IReport> = {
    key: 'compare',
    title: 'Comparer',
    render: compareButton,
  };

  const columns: ColumnProps<IReport>[] = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Titre',
      sorter: (a, b) => (a.title > b.title ? 1 : -1),
      render: (title) => <strong>{title}</strong>,
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Type',
      sorter: (a, b) => (a.type > b.type ? 1 : -1),
      render: (type) => <strong>{type}</strong>,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Date',
      sorter: (a, b) => {
        const dateTimeA = new Date(a.date).getTime();
        const dateTimeB = new Date(b.date).getTime();
        return dateTimeA - dateTimeB;
      },
      render: (date) => {
        if (!date) return null;

        const [year, month, day] = date.split('T')[0].split('-');
        const formattedDate = `${day}/${month}/${year}`;

        return <span className='nowrap' style={{ color: '#a5a5a5' }}>{formattedDate}</span>;
      },
    },
  ];

  const closeModal = () => {
    setReport(null);
    setVisibility(false);
  };

  const handleEditReports = (report: IReport) => {
    const editedReports = data.map((el) => (el._id !== selectedReport._id ? el : report));
    setReports(editedReports);
    setSelectedReport(null);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Filter data based on search text and filter type
  const filteredData = data
  .filter((report) => {
    const isoDate = report.date ? report.date.split('T')[0] : '';
    const [year, month, day] = isoDate.split('-');
    const formattedDate = `${day}/${month}/${year}`;

    return (
      formattedDate.includes(searchText) || // Partial date match
      report.title?.toLowerCase().includes(searchText.toLowerCase()) ||
      report.type?.toLowerCase().includes(searchText.toLowerCase())
    );
  })
  .filter((report) => {
    // Normalize the strings to handle accents and ensure case-insensitive comparison
    const reportType = report.type?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const selectedFilterType = filterType.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return filterType === 'all' || reportType === selectedFilterType;
  });

  const displayedColumns = filteredData.length >= 2 ? [...columns, actionColumn, compareColumn] : [...columns, actionColumn];

  return (
    <>
      <label className='search-label'>Rechercher</label>
      <Input
        className='search-input'
        placeholder='Recherche par titre, par type ou par date: dd/mm/aaaa'
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: '17px', border: '1px solid #ccc' }}
      />
      <div className='filter-options' style={{ marginBottom: 20 }}>
        <Radio.Group onChange={(e) => setFilterType(e.target.value)} value={filterType}>
          <Radio value='all'>Tous</Radio>
          <Radio value='diagnostiques'>Diagnostiques</Radio>
          <Radio value='essai de prothèse'>Essai de Prothèse</Radio>
          <Radio value="controle d'appareillage">Contrôle d'Appareillage</Radio>
          <Radio value='Anamnèse'>Anamnèse</Radio> {/* Newly added */}
        <Radio value="Test d'audition">Test d'Audition</Radio> {/* Newly added */}
        </Radio.Group>
      </div>
      <Table rowKey='_id' dataSource={filteredData} columns={displayedColumns} pagination={{ hideOnSinglePage: true }} />
      <EditReport
        report={selectedReport}
        patientId={patient._id}
        onClose={closeModal}
        visible={visibility}
        onSubmit={handleEditReports}
      />
    </>
  );
};

export default AdminReportsByPatientTable;
