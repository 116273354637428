import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { Form, Button, Input, Divider } from 'antd';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../utils/hasError';
import { IUser } from '../../../../interfaces/user';
import api from '../../../../api';

const { TextArea } = Input;

type Props = {
  onSubmit: (doctor: IUser) => void;
  onCancel: () => void;
  doctor: IUser;
};

const doctorScheme = Yup.object({
  firstName: Yup.string().required('Prénom requis'), // First Name -> Prénom (required)
  lastName: Yup.string().required('Nom requis'), // Last Name -> Nom (required)
  phone: Yup.string().required('Téléphone requis'), // Phone -> Téléphone (required)
  address: Yup.string().required('Adresse requise'), // Address -> Adresse (required)
});

const initialValues = {
  role: null,
  firstName: null,
  username: null,
  lastName: null,
  email: null,
  phone: null,
  img: null,
  gender: null,
  address: null,
  social: [
    {
      icon: 'icofont-instagram',
      link: '#',
    },
    {
      icon: 'icofont-facebook',
      link: '#',
    },
    {
      icon: 'icofont-twitter',
      link: '#',
    },
  ],
};

const DoctorForm = ({ onSubmit, onCancel, doctor }: Props) => {
  const [addingError, setAddingError] = useState<string | null>(null);
  const {
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
  } = useFormik<IUser>({
    initialValues,
    validationSchema: doctorScheme,
    validateOnMount: true,
    initialErrors: { firstName: null },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const addDoctor = async (vals) => {
    try {
      const response = await api.put('doctor', vals);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    console.log('errors');
    console.log(errors);
    onCancel();
  };

  const handleSubmit = async () => {
    if (!isValid) return;
    try {
      await api.put(`doctor/${values._id}`, values).then((resp) => {
        console.log('edited');
        console.log(resp.data);
        setAddingError(null);
        onSubmit(values);
        onCancel();
      });
    } catch (error) {
      console.error(error);
      setAddingError('');
    }
  };

  useEffect(() => {
    setValues(doctor);
  }, []);

  const hasError = hasErrorFactory(touched, errors);

  return (
    <>
      <Form>
        <div className='form-group'>
          <label htmlFor='firstName'>Prénom</label>
          <Input
            id='firstName'
            name='firstName'
            type='text'
            placeholder='Prénom'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            className={hasError('firstName')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='lastName'>Nom</label>
          <Input
            id='lastName'
            type='text'
            name='lastName'
            placeholder='Nom'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            className={hasError('lastName')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='phone'>Téléphone</label>
          <Input
            id='phone'
            type='number'
            name='phone'
            placeholder='Téléphone'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            className={hasError('phone')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='address'>Adresse</label>
          <TextArea
            id='address'
            rows={3}
            name='address'
            placeholder='Adresse'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.address}
            className={hasError('address')}
          />
        </div>

        <Divider />

        <div className='d-flex justify-content-between buttons-list settings-actions mt-4'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} onClick={handleSubmit} htmlType='submit' type='primary'>
            Modifier mon profil
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DoctorForm;
