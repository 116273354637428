import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './pdfViewer.scss';
import { useLocation } from 'react-router-dom';
import api from "../../../../../../../../api";
import { IPageData } from "../../../../../../../../interfaces/page";
import { usePageData } from "../../../../../../../../hooks/usePage";
import { Button } from 'antd';
import { IReport } from "../../../../../../../../interfaces/patient";
import { getUserRole, isLoggedIn } from '../../../../../../../../utils/auth';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const CompareReportsPage: React.FC = () => {
    // Create separate toolbarPlugin instances for each PDF viewer
    const toolbarPluginInstance1 = toolbarPlugin();
    const toolbarPluginInstance2 = toolbarPlugin();

    const { renderDefaultToolbar: renderDefaultToolbar1, Toolbar: Toolbar1 } = toolbarPluginInstance1;
    const { renderDefaultToolbar: renderDefaultToolbar2, Toolbar: Toolbar2 } = toolbarPluginInstance2;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        SwitchScrollMode: () => <></>,
        SwitchScrollModeMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        ShowProperties : () => <></>,
        ShowPropertiesMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    const location = useLocation();
    const [report1, setReport1] = useState<IReport | null>(null);
    const [report2, setReport2] = useState<IReport | null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const report1Id = queryParams.get('report1');
        const report2Id = queryParams.get('report2');

        if (report1Id) {
            api.get(`report/${report1Id}`).then(response => {
                setReport1(response.data);
            });
        }

        if (report2Id) {
            api.get(`report/${report2Id}`).then(response => {
                setReport2(response.data);
            });
        }
    }, [location.search]);

    const isDoctor = isLoggedIn && getUserRole() === "doctor";
    const isAdmin = isLoggedIn && getUserRole() === "admin";

    const pageData: IPageData = {
        title: 'Comparer les rapports',
        fulFilled: true,
        breadcrumbs: [
            isDoctor ? {
                title: 'Mon Profile',
                route: '../doctor/my-profile'
            } : {
                title: 'Medicine',
                route: '../admin/default-dashboard'
            },
            isAdmin && {
                title: 'Patients',
                route: '../admin/patients'
            },
            {
                title: 'Comparer les rapports'
            }
        ]
    };
    usePageData(pageData);

    return (
        <div className="compare-reports-page">
            <div className="buttons-container">
                {report1 && (
                    <a
                        className="no-decoration-link"
                        href={report1.url}
                        download={`report-${report1._id}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button type="primary">
                            Télécharger le 1er rapport
                        </Button>
                    </a>
                )}
                {report2 && (
                    <a
                        className="no-decoration-link"
                        href={report2.url}
                        download={`report-${report2._id}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button type="primary">
                            Télécharger le 2ème rapport
                        </Button>
                    </a>
                )}
            </div>
            <div className="pdf-viewer-container">
                {report1 && (
                    <div className="pdf-viewer">
                        <div className="download-button">
                            <br />
                            <br />
                            <br />
                        </div>
                        <Toolbar1>{renderDefaultToolbar1(transform)}</Toolbar1>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11/build/pdf.worker.min.js">
                            <Viewer fileUrl={report1.url} plugins={[toolbarPluginInstance1]} />
                        </Worker>
                    </div>
                )}
                {report2 && (
                    <div className="pdf-viewer">
                        <div className="download-button">
                            <br />
                            <br />
                            <br />
                        </div>
                        <Toolbar2>{renderDefaultToolbar2(transform)}</Toolbar2>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11/build/pdf.worker.min.js">
                            <Viewer fileUrl={report2.url} plugins={[toolbarPluginInstance2]} />
                        </Worker>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompareReportsPage;
