import React from 'react';
import { IAppointment, IControlPatient } from '../../../../../../interfaces/patient';
import { Modal } from 'antd';
import AppointmentEditForm from "./ControlPatientEditForm";

type Props = {
  onEdited: (IControlPatient) => void;
  controlPatient: IControlPatient;
  visible: boolean;
  onClose: () => void;
};

const EditCP = ({ controlPatient, visible, onClose, onEdited }: Props) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      title={<h3 className='title'>Modifier le nettoyage</h3>}
    >
      <AppointmentEditForm
        onCancel={onClose}
        onSubmit={onEdited}
        controlPatient={controlPatient}
        submitText='Modifier le nettoyage'
      />
    </Modal>
  );
};

export default EditCP;
