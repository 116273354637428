import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';
import { useForm } from 'antd/es/form/Form';
import PublicLayout from '../../../../layout/public/Public';
import { useNavigateHome } from '../../../../utils/use-navigate-home';
import { api_url } from '../../../../domain/back';
import axios from 'axios';
import { useNavigateDoctorsList } from '../../../../utils/use-navigate-doctors-list';

const SignIn = () => {
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const navigateDoctorsList = useNavigateDoctorsList();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const login = () => {
    form
      .validateFields()
      .then(async () => {
        const { login, password } = form.getFieldsValue(['login', 'password']);
        try {
          const response = await axios
            .post(api_url + 'auth/login', {
              username: login,
              password: password,
            })
            .then((response) => {
              if (response.data['access_token']) {
                localStorage.setItem(
                  'audiologist_token',
                  response.data['access_token']
                );
                navigateHome();
              } else {
                setError(true);
                setErrorMessage('Nom d\'utilisateur ou mot de passe incorrect!');
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.error('Login failed:', error);
          // Handle error (e.g., show a notification)
        }
      })
      .catch(() => null);
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/bg-card-1.jpg`}>
      <h4 className='mt-0 mb-1'>Bienvenue Administrateur!</h4>

      <p className='text-color-200'>
        Connectez-vous pour accéder à votre compte
      </p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Form.Item
          name='login'
          rules={[{ required: true, message: 'Nom d\'utilisateur requis' }]}
          validateStatus={error ? 'error' : ''}
        >
          <Input
            placeholder="Nom d'utilisateur"
            style={error ? { borderColor: '#ed5564' } : {}}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: 'Mot de passe requis' }]}
          validateStatus={error ? 'error' : ''}
        >
          <Input
            placeholder='Mot de passe'
            type='password'
            style={error ? { borderColor: '#ed5564' } : {}}
          />
        </Form.Item>

        <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Se souvenir de moi</span>
        </div>
        {errorMessage && (
          <p style={{ color: '#ed5564', marginTop: '1rem' }}>{errorMessage}</p>
        )}
        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Connexion
        </Button>
      </Form>
      <br />
      {/* <p className='mb-1'>
        <a href='#'>Mot de passe oublié?</a>
      </p>

      <p>
        Vous n'avez pas de compte? <Link to='../sign-up'>Inscrivez-vous!</Link>
      </p> */}
    </PublicLayout>
  );
};

export default SignIn;
