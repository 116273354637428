import React, { useState } from 'react';

import { usePageData } from '../../../../../../hooks/usePage';

import { IAppointment, IControlPatient, IPatient, IReport } from '../../../../../../interfaces/patient'; // Update interface names (RendezVous, Rapport)
import { IPageData } from '../../../../../../interfaces/page';
import { useAdminFetchPageData } from "../../../../../../hooks/useAdminPage";
import AdminAppointmentsTable from "./Appointment/AppointmentsTable";
import { Link, NavLink, useParams } from "react-router-dom";
import AdminReportsByPatientTable from "./Report/ReportsTable";
import { IUser } from "../../../../../../interfaces/user"; // Update interface name (Utilisateur)
import "./patient-details.scss";
import { Button } from 'antd';
import AdminControlPatientTable from './ControlePatient/ControlPatientsTable';

const DoctorDetailsPatientPage = () => {
  const { patientId } = useParams();
  const [activeReports, setActiveReports] = useState<string[]>([]);
  const [rendezVous, setRendezVous] = useAdminFetchPageData<IAppointment[]>(
    `appointement/patient/${patientId}`, // Update endpoint (rendezVous)
    []
  );
  const [patient, setPatient] = useAdminFetchPageData<IPatient>(
    `patient/${patientId}`,
    { address: "", email: "", firstName: "", lastName: "" }
  );
  const [doctor] = useAdminFetchPageData<IUser>( // Update endpoint/interface (Utilisateur)
    `docteur/${patient.doctor}`, // Update endpoint (docteur)
    { address: "", email: "", firstName: "", lastName: "", username: "" }
  );
  const [rapports, setRapports] = useAdminFetchPageData<IReport[]>( // Update endpoint/interface (rapports)
    `report/patient/${patientId}`,
    []
  );
  const [controlPatients, setControPatients] = useAdminFetchPageData<IControlPatient[]>(
    `cp/patient/${patientId}`,
    []
  );
  const pageData: IPageData = {
    title: `Détails de M. ${patient.lastName}`, // Update title (Détails)
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Mes Patients', // Update title (Mes Patients)
        route: '../doctor/my-patients'
      },
      {
        title: `Détails de M. ${patient.lastName}` // Update title (Détails)
      }
    ]
  };
  usePageData(pageData);

  const handleSetRapports = (updatedReports: IReport[]) => {
    console.log(updatedReports.length);
    setRapports(updatedReports);
  };

  const updateActiveReports = (newActiveReports: string[]) => {
    setActiveReports(newActiveReports);
  };

  return (
    <>
      <div className="title-heading">
        <h2>Rendez-vous :</h2> {/* Update title (Rendez-vous) */}

        <NavLink className="btn btn-secondary" to={`/doctor/patient-calendar/${patientId}`}>
          <span className="icofont icofont-ui-calendar" />
        </NavLink>
      </div>
      <AdminAppointmentsTable patient={patient} data={rendezVous} />

      <div className="title-heading report">
        <h2>Rapports :</h2>  {/* Update title (Rapports) */}

        <div className="btn-container">
          {activeReports.length === 2 && (
              <NavLink
                to={`../compare-report/?report1=${activeReports[0]}&report2=${activeReports[1]}`}
              >
              <Button>
                  Voir la comparaison
              </Button>
            </NavLink>
          )}
        </div>
      </div>

      <AdminReportsByPatientTable
        setReports={handleSetRapports}
        patient={patient}
        data={rapports}
        updateActiveReports={updateActiveReports}
      />
      <div className="title-heading">
        <h2>Utilisation & Nettoyage :</h2>

       
      </div>
    <AdminControlPatientTable patient={patient} data={controlPatients}  />
    </>
  );
};

export default DoctorDetailsPatientPage;
