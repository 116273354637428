import { IRoute } from '../interfaces/routing';

import PatientsPage from '../pages/roles/admin/patient/patients/Patients';
import DoctorProfile from '../pages/roles/admin/doctor/doctor-profile/DoctorProfile';
import DoctorCurrentProfile from '../pages/roles/doctor/my-profile/DoctorCurrentProfile';
import MyCurrentPatientsPage from "../pages/roles/doctor/my-patients/MyPatients";
import DoctorDetailsPatientPage from "../pages/roles/doctor/my-patients/my-patients/patient-details/patientDetailsPage";
import PatientCalendarPage
  from "../pages/roles/doctor/my-patients/my-patients/patient-details/Appointment/PatientCalendarPage";
import ReportPage from "../pages/roles/doctor/my-patients/my-patients/patient-details/Report/pdfViewer/reportPage";
import CompareReportsPage from '../pages/roles/admin/patient/patient-details/Report/pdfViewer/compareReports/reportPage';
import DoctorConfimPassword from '../pages/roles/doctor/my-profile/confimPassword/ConfirmPassword';

export const defaultDoctorRoutes: IRoute[] = [
  {
    path: 'patients',
    component: PatientsPage
  },
  {
    path: 'my-profile',
    component: DoctorCurrentProfile
  },
  {
    path: 'my-patients',
    component: MyCurrentPatientsPage,
  },
  {
    path: 'patient-details/:patientId',
    component: DoctorDetailsPatientPage
  },
  {
    path: 'patient-calendar/:id',
    component: PatientCalendarPage
  },
  {
    path: 'report/:id',
    component: ReportPage
  },
  {
    path: 'compare-report',
    component: CompareReportsPage
  },
  {
    path: 'change-password',
    component: DoctorConfimPassword
  },
];
