import React from 'react';
import { NavLink } from 'react-router-dom';
import { Avatar, Dropdown } from 'antd';
import {urlRole} from "../../../token/route";
import {jwtDecode} from "jwt-decode";
import {audiologistToken} from "../../../token/doctor-token";



const accountItems = [
  { text: 'Changer votre mot de passe', icon: 'icofont-logout', route: `/doctor/change-password` },
  { text: 'Se déconnecter', icon: 'icofont-logout', route: `/public/doctor-sign-in` },
];

const SettingsDropdown = () => {
  const accountMenu = () => (
    <ul className='actions-menu' style={{ minWidth: '180px' }}>
      {accountItems.map((item, index) => (
        <>
          {item.route === `/public/doctor-sign-in` || item.route === `/public/admin-sign-in`?
          <li className='action-item' key={index} onClick={()=>{
            console.log("logging out")
            if(audiologistToken) {
              localStorage.removeItem("audiologist_token")
            }
          }}>
            <NavLink className='d-flex w-100' to={item.route} replace>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </NavLink>
          </li>
          :
          <li className='action-item' key={index}>
            <NavLink className='d-flex w-100' to={item.route} replace>
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>{item.text}</span>
            </NavLink>
          </li>
      }
        </>
      ))}
    </ul>
  );

  return (
    <Dropdown dropdownRender={accountMenu} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar
          size={40}
          className='mr-1'
          src={`${window.location.origin}/content/default-user.png`}
        />
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
