import React, { useEffect, useState } from 'react';
import { usePatients } from '../../../../hooks/usePatients'; // Check if this hook is actually used

import PatientsTable from './my-patients/PatientsTable'; // Assuming PatientsTable displays patients

import { IPageData } from '../../../../interfaces/page';
import { audiologistToken, decodedToken } from "../../../../token/doctor-token";
import api from "../../../../api";
import { api_url } from "../../../../domain/back";
import "./MyPatients.style.scss"; // Assuming this styles the component
import { usePageData } from "../../../../hooks/usePage";

const pageData: IPageData = {
  title: 'Mes patients', // Patients -> Mes patients
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Mes patients', // Patients -> Mes patients
    },
  ],
};

const MyCurrentPatientsPage = () => {
  usePageData(pageData);
  const [myPatients, setMyPatients] = useState([]); // List of patients
  const [id, setId] = useState(""); // Doctor ID (optional)

  useEffect(() => {
    const fetchPatientsByDoc = async () => {
      console.log("ID du docteur : " + id); // Doctor ID: + id
      const response = await api.get(api_url + "patient/doctor/" + (audiologistToken ? decodedToken["sub"] : "")).then(response => {
        console.log(response.data);
        setMyPatients(response.data);
      }).catch(err => console.error(err));
    };

    fetchPatientsByDoc();
  }, []);

  return (
    <>
      <PatientsTable
        patients={myPatients}
      />
    </>
  );
};

export default MyCurrentPatientsPage;
