import { IRoute } from '../interfaces/routing';

import PatientsPage from '../pages/roles/admin/patient/patients/Patients';
import DashboardPage from '../pages/dashboards/dashboard/Dashboard';
import DoctorsPage from '../pages/roles/admin/doctor/Doctors';
import DoctorProfile from '../pages/roles/admin/doctor/doctor-profile/DoctorProfile';
import DoctorCurrentProfile from '../pages/roles/doctor/my-profile/DoctorCurrentProfile';
import AdminAppointmentsByPatientPage from "../pages/roles/admin/patient/patient-details/patientDetailsPage";
import ReportPage from "../pages/roles/admin/patient/patient-details/Report/pdfViewer/reportPage";
import PatientCalendarPage from "../pages/roles/admin/patient/patient-details/Appointment/PatientCalendarPage";
import CompareReportsPage from '../pages/roles/admin/patient/patient-details/Report/pdfViewer/compareReports/reportPage';

export const defaultRoutes: IRoute[] = [
  {
    path: 'patients',
    component: PatientsPage
  },
  {
    path: 'default-dashboard',
    component: DashboardPage
  },
  {
    path: 'report/:id',
    component: ReportPage
  },
  {
    path: 'compare-report',
    component: CompareReportsPage
  },
  {
    path: 'patient-details/:patientId',
    component: AdminAppointmentsByPatientPage
  },
  {
    path: 'doctors',
    component: DoctorsPage
  },
  {
    path: 'doctor-profile/:id',
    component: DoctorProfile
  },
  {
    path: 'my-profile',
    component: DoctorCurrentProfile
  },
  {
    path: 'patient-calendar/:id',
    component: PatientCalendarPage
  },
];
