import React, { useState } from 'react';
import { Button, Input, Radio, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IPatient, IReport } from '../../../../../../../interfaces/patient';
import { Link } from 'react-router-dom';
import './reportTable.scss';

type Props = {
  patient: IPatient;
  data: IReport[];
  onEditReport?: (patient: IPatient) => void;
  setReports?: (reports: IReport[]) => void;
  onDeleteReport?: (id: string) => void;
  updateActiveReports: (activeReports: string[]) => void;
};

const DoctorReportsByPatientTable = ({
  patient,
  data,
  setReports,
  onEditReport = () => null,
  onDeleteReport = () => null,
  updateActiveReports,
}: Props) => {
  const [activeReports, setActiveReports] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState('all');

  const handleToggleCompare = (reportId: string) => {
    setActiveReports((prevActiveReports) => {
      let newActiveReports = prevActiveReports;
      if (prevActiveReports.includes(reportId)) {
        newActiveReports = prevActiveReports.filter((id) => id !== reportId);
      } else if (prevActiveReports.length < 2) {
        newActiveReports = [...prevActiveReports, reportId];
      }
      updateActiveReports(newActiveReports);
      return newActiveReports;
    });
  };

  const actions = (report: IReport) => (
    <div className='buttons-list nowrap'>
      <Link to={`/doctor/report/${report._id}?patient=${patient._id}`}>
        <Button shape='circle'>
          <span className='icofont icofont-external-link' />
        </Button>
      </Link>
    </div>
  );

  const compareButton = (report: IReport) => (
    <Button
      className={`btn-select ${activeReports.includes(report._id) ? 'active' : ''}`}
      onClick={() => handleToggleCompare(report._id)}
      disabled={!activeReports.includes(report._id) && activeReports.length >= 2}
    >
      {activeReports.includes(report._id) ? 'Sélectionné' : 'Sélectionner'}
    </Button>
  );

  const actionColumn: ColumnProps<IReport> = {
    key: 'actions',
    title: 'Voir',
    render: actions,
  };

  const compareColumn: ColumnProps<IReport> = {
    key: 'compare',
    title: 'Comparer',
    render: compareButton,
  };

  const columns: ColumnProps<IReport>[] = [
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Titre',
      sorter: (a, b) => (a.title > b.title ? 1 : -1),
      render: (title) => <strong>{title}</strong>,
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Type',
      sorter: (a, b) => (a.type > b.type ? 1 : -1),
      render: (type) => <strong>{type}</strong>,
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'Date',
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (date) => {
        if (!date) return null;
        const [year, month, day] = date.split('T')[0].split('-');
        const formattedDate = `${day}/${month}/${year}`;
        return <span className='nowrap' style={{ color: '#a5a5a5' }}>{formattedDate}</span>;
      },
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // Filter data based on search text and filter type
  // const filteredData = data
  //   .filter((report) => {
  //     const isoDate = report.date ? report.date.split('T')[0] : '';
  //     const [year, month, day] = isoDate.split('-');
  //     const formattedDate = `${day}/${month}/${year}`;

  //     return (
  //       formattedDate.includes(searchText) || // Partial date match
  //       report.title?.toLowerCase().includes(searchText.toLowerCase()) ||
  //       report.type?.toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   })
  //   .filter((report) => filterType === 'all' || report.type?.toLowerCase() === filterType.toLowerCase());
  const filteredData = data
  .filter((report) => {
    const isoDate = report.date ? report.date.split('T')[0] : '';
    const [year, month, day] = isoDate.split('-');
    const formattedDate = `${day}/${month}/${year}`;

    return (
      formattedDate.includes(searchText) || // Partial date match
      report.title?.toLowerCase().includes(searchText.toLowerCase()) ||
      report.type?.toLowerCase().includes(searchText.toLowerCase())
    );
  })
  .filter((report) => {
    // Normalize the strings to handle accents and ensure case-insensitive comparison
    const reportType = report.type?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const selectedFilterType = filterType.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return filterType === 'all' || reportType === selectedFilterType;
  });

  const displayedColumns = filteredData.length >= 2 ? [...columns, actionColumn, compareColumn] : [...columns, actionColumn];

  return (
    <>
      <label className='search-label'>Rechercher</label>
      <Input
        className='search-input'
        placeholder='Recherche par titre, par type ou par date: dd/mm/aaaa'
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: '17px', border: '1px solid #ccc' }}
      />
      <div className='filter-options' style={{ marginBottom: 20 }}>
        <Radio.Group onChange={(e) => setFilterType(e.target.value)} value={filterType}>
          <Radio value='all'>Tous</Radio>
          <Radio value='Diagnostiques'>Diagnostiques</Radio>
          <Radio value='Essai de prothése'>Essai de Prothèse</Radio>
          <Radio value="Contrôle d'appareillage">Contrôle d'Appareillage</Radio>
          <Radio value='Anamnèse'>Anamnèse</Radio> {/* Newly added */}
        <Radio value="Test d'audition">Test d'Audition</Radio> {/* Newly added */}
        </Radio.Group>
      </div>
      <Table rowKey='_id' dataSource={filteredData} columns={displayedColumns} pagination={{ hideOnSinglePage: true }} />
    </>
  );
};

export default DoctorReportsByPatientTable;
