// src/services/authService.ts
interface DecodedToken {
    username: string;
    sub: string;
    role: string;
    iat: number;
    exp: number;
  }
  
  export const getDecodedToken = (): DecodedToken | null => {
    const token = localStorage.getItem('audiologist_token');
    if (!token) return null;
    try {
      return JSON.parse(atob(token.split('.')[1])) as DecodedToken;
    } catch (e) {
      console.error('Failed to decode token:', e);
      return null;
    }
  };
  
  export const getUserRole = (): string | null => {
    const decodedToken = getDecodedToken();
    return decodedToken ? decodedToken.role : null;
  };
  
  export const isLoggedIn = (): boolean => {
    return !!localStorage.getItem('audiologist_token');
  };
  