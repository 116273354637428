import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Card } from 'antd';
import hospitalOptions from './charts/hospital-options';
import { incomeInWeek, incomeInMonth } from './charts/income-options';
import {
  patientsGenderOptions,
  departmentsOptions,
  patientsAgeOptions
} from './charts/patients-options';
import { useFetchPageData, usePageData } from '../../../hooks/usePage';
import { IAppointment } from '../../../interfaces/patient';
import { IPageData } from '../../../interfaces/page';
import AppointmentsTable from '../../roles/admin/appointment/AppointmentsTable';
import { useAdminFetchPageData } from '../../../hooks/useAdminPage';
import DoctorsTable from '../../roles/admin/doctor/DoctorsTable/DoctorsTable';
import { IUser } from '../../../interfaces/user';
import axios from 'axios';
import { api_url } from '../../../domain/back';
import withAuthorization from '../../../hocs/withAuthorization';

const pageData: IPageData = {
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Tableau de bord'
    }
  ]
};

const DashboardPage: React.FC = () => {
  const [appointments] = useAdminFetchPageData<IAppointment[]>('appointement', []);
  const [doctors] = useAdminFetchPageData<IUser[]>('doctor', []);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [doctorCount, setDoctorCount] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const baseUrl = `${api_url}`;

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const token = localStorage.getItem('audiologist_token');
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const appointmentResponse = await axios.get(`${baseUrl}appointement/count/appointement`, config);
        setAppointmentCount(appointmentResponse.data);

        const doctorResponse = await axios.get(`${baseUrl}doctor/count/doctors`, config);
        setDoctorCount(doctorResponse.data);

        const patientResponse = await axios.get(`${baseUrl}patient/count/patient`, config);
        setPatientCount(patientResponse.data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };
    fetchCounts();
    console.log(appointments);
  }, []);

  usePageData(pageData);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-first-aid-alt'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>
              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Rendez-vous</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {appointmentCount}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-wheelchair'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>
              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Les patients</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {patientCount}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-blood'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>
              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Les docteurs</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {doctorCount}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Card title='Les Docteurs' className='mb-0'>
        <DoctorsTable data={doctors} />
      </Card>
      <br />
      <br />
      <Card title='Les rendez-vous' className='mb-0'>
        <AppointmentsTable data={appointments} />
      </Card>
    </>
  );
};

export default withAuthorization(DashboardPage, ['admin']);
