import React from 'react';

import { Button, Select, Input, Card } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hasErrorFactory } from '../../../../../utils/hasError';

import { IPatient } from '../../../../../interfaces/patient';
import { selectOptionFilter } from '../../../../../hooks/selectOptionFilter';

const Option = Select.Option;

const { TextArea } = Input;
type Props = {
  onSubmit: (patient: IPatient) => void;
  onCancel: () => void;
  patient?: IPatient;
  submitText?: string;
};

const defaultSubmitText = 'Ajouter un patient';
const emptyPatient = {
  firstName: null,
  lastName: null,
  email:null,
  address: null,
  doctor:null,
  phone: null,
  status: null,
  age: null,
  number: null,
  gender: null,
  sexe:null,
  img: null
};

const patientScheme = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  doctor: Yup.string().required(),
  phone: Yup.string().required(),
  address: Yup.string().required(),
  age: Yup.string().required(),
  gender: Yup.string().required(),
  email: Yup.string().required().email("email non valide"),
});

const PatientForm = ({
  submitText = defaultSubmitText,
  patient = emptyPatient,
  onSubmit,
  onCancel
}: Props) => {
  const {
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    resetForm,
    touched,
    values,
    errors,
    isValid
  } = useFormik<IPatient>({
    validationSchema: patientScheme,
    initialValues: patient,
    onSubmit: (values) => {
      onSubmit(values);
      onCancel();
    }
  });

  // const handleGenderSelect = (value) => setFieldValue('gender', value);
  // const handleStatusSelect = (value) => setFieldValue('status', value);
  const handleDoctorSelect = (value) => setFieldValue('doctor', value);

  const hasError = hasErrorFactory(touched, errors);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleImageLoad = (img) => {
    setValues({ ...values, img });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-container">
        <div className='form-group'>
          <label htmlFor='firstName'>Prénom</label>
          <Input
              id='firstName'
              placeholder='Prénom'
              name='firstName'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.firstName}
              className={hasError('firstName')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='lastName'>Nom</label>
          <Input
              id='lastName'
              placeholder='Nom'
              name='lastName'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.lastName}
              className={hasError('lastName')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='doctor'>Docteur</label>
          <Select
              id='doctor'
              showSearch
              placeholder='Sélectionnez le docteur'
              optionFilterProp='children'
              filterOption={selectOptionFilter}
              onChange={handleDoctorSelect}
              onBlur={() => setFieldTouched('doctor')}
              className={hasError('doctor')}
              value={values.doctor._id}
          >
            <Option value='jack'>Jack</Option>
            <Option value='lucy'>Lucy</Option>
            <Option value='tom'>Tom</Option>
          </Select>
        </div>

        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <Input
              id='email'
              placeholder='Email'
              name='email'
              type='email'
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.email}
              className={hasError('email')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='phone'>Téléphone</label>
          <Input
              id='phone'
              placeholder='Téléphone'
              name='phone'
              type='text'
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.phone}
              className={hasError('phone')}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='address'>Adresse</label>
          <TextArea
              id='address'
              rows={3}
              name='address'
              placeholder='Adresse'
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.address}
              className={hasError('address')}
          />
        </div>

        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default PatientForm;
