import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { Table, Button, Modal, Input } from 'antd';
import { IPatient } from '../../../../../interfaces/patient';
import PatientForm from '../addPtientToDoctor/PatientByDoctorForm';
import api from "../../../../../api";

type Props = {
  patients: IPatient[];
  onEditPatient?: (patient: IPatient) => void;
  onDeletePatient?: (id: string) => void;
  setPatients: (patients: IPatient[]) => void;
};

const PatientsTable = ({
  patients,
  onEditPatient = () => null,
  onDeletePatient = () => null,
  setPatients
}: Props) => {

  const navigate = useNavigate();
  const [patient, setPatient] = useState<IPatient | null>(null);
  const [visibility, setVisibility] = useState(false);
  const [searchText, setSearchText] = useState('');

  const closeModal = () => setVisibility(false);

  const handleShowInfo = (patient: IPatient) => navigate(`/admin/patient-details/${patient._id}`);
  const handleDeletePatient = (id: string) => {
    Modal.confirm({
      title: 'Êtes-vous sûr de vouloir supprimer ce patient?',
      content: 'Cette action est irréversible.',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk: async () => {
        try {
          await api.delete(`patient/${id}`);
          setPatients(patients.filter(patient => patient._id !== id));
          onDeletePatient(id);
        } catch (error) {
          console.error('Erreur lors de la suppression du patient:', error);
        }
      }
    });
  };

  const handleEditPatient = (patient: IPatient) => {
    setPatient(patient);
    setVisibility(true);
  };

  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={() => handleShowInfo(patient)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Button onClick={() => handleDeletePatient(patient._id)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div>
  );

  const columns: ColumnProps<IPatient>[] = [
    {
      key: 'fullName',
      title: 'Nom Complet',
      sorter: (a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName),
      render: (patient: IPatient) => <strong>{`${patient.firstName} ${patient.lastName}`}</strong>
    },
    {
      key: 'age',
      dataIndex:'age',
      title: 'Age',
      sorter: (a, b) => a.age > b.age ? 1 : -1,
      render: (age: string) => <strong>{age && age}</strong>
    },
    {
      key: 'sexe',
      dataIndex:'sexe',
      title: 'Sexe',
      sorter: (a, b) => a.sexe > b.sexe ? 1 : -1,
      render: (sexe: string) => <strong>{sexe && sexe}</strong>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render: (email) => (
        <strong style={!email ? { color: "red" } : {}}>{email ? email : "non ajouté"}</strong>
      )
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Téléphone',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
            {phone}
        </span>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      render: actions
    }
  ];

  const pagination = patients.length <= 5 ? false : {};

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredPatients = patients.filter(patient =>
    patient.email?.toLowerCase().includes(searchText.toLowerCase()) ||
    (patient.firstName + ' ' + patient.lastName)?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <label className="search-label">Rechercher</label>
      <Input
        className="col-md-6 col-sm-12 search-input"
        placeholder="Rechercher par email, nom complet ou médecin"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
      />
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='_id'
        dataSource={filteredPatients}
        columns={columns}
      />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        title={
          <h3 className='title'>Patient {patient && patient.firstName}</h3>
        }
      >
        <PatientForm
          submitText='Mettre à jour le patient'
          onCancel={closeModal}
          onSubmit={onEditPatient}
          patient={patient}
        />
      </Modal>
    </>
  );
};

export default PatientsTable;
