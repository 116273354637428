// PdfViewer.tsx
import React, {useEffect, useState} from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './pdfViewer.scss';
import {useParams} from "react-router-dom";
import {IReport} from "../../../../../../../interfaces/patient";
import api from "../../../../../../../api";
import {IPageData} from "../../../../../../../interfaces/page";
import {usePageData} from "../../../../../../../hooks/usePage";
import { Button } from 'antd';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot,TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';


type PdfViewerProps = {
    // url: string;
};

const ReportPage: React.FC<PdfViewerProps> = () => {
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        ShowProperties : () => <></>,
        ShowPropertiesMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });
    const {id} = useParams();
    const [report, setReport] = useState<IReport>(

    );
    useEffect(() => {
        const getReport=async ()=>{
            await api.get(`report/${id}`).then(response=>{
                setReport(response.data)
            })
        }
        getReport()
    }, [report]);
    const pageData: IPageData = {
        title: `Voir le rapport`,
        fulFilled: true,
        breadcrumbs: [
            {
                title: 'Medicine',
                route: '../admin/default-dashboard'
            },
            {
                title: 'Patients',
                route: '../admin/patients'
            },
            {
                title: `Voir le rapport`
            }
        ]
    };
    usePageData(pageData);
    const url = "https://eu2.contabostorage.com/2d799892734c428a97df8b900cefee34:rapport/c850c91df95990fe0ef9-pdf-example.pdf"
    const newPlugin = defaultLayoutPlugin()
    return (
        <>
            <div className="download-button">
                    {report && <a 
                        className="no-decoration-link" 
                        href={report.url} 
                        style={{textDecoration:"none"}} 
                        download={`report-${id}.pdf`} 
                        target="_blank" rel="noopener noreferrer">

                        <Button type="primary">
                            Télécharger le rapport
                        </Button>
                        
                    </a>}
            </div>
            <br />
            <div className="pdf-viewer">
                <br />
                <br />

            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>

                { 

                report &&<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11/build/pdf.worker.min.js`}>

                    <Viewer fileUrl={report.url} plugins={[toolbarPluginInstance]}/>
                </Worker>}
            </div>
        </>
    );
};

export default ReportPage;
