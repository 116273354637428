import React, { useEffect } from 'react';
import { Button, DatePicker, TimePicker, Input, Form, DatePickerProps, Select, notification } from 'antd';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../../utils/hasError';
import { IAppointment } from '../../../../../../interfaces/patient';
import api from "../../../../../../api";
import { selectOptionFilter } from "../../../../../../hooks/selectOptionFilter";
import { title } from 'process';

type Props = {
  patientId?: string;
  appointment?: IAppointment;
  onSubmit: (appointment: IAppointment) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Ajouter Rendez-vous';
const emptyAppointment = {
  patientId: "",
  title:'',
  date: null,
  time: '',
};

const appointmentSchema = Yup.object().shape({
  date: Yup.string().required('La date est requise'),
  title: Yup.string().required('Le titre est requis'),
  time: Yup.string().required('L\'horaire est requise'),
});

const AppointmentForm = ({
                           patientId,
                           submitText = defaultSubmitText,
                           appointment = emptyAppointment,
                           onSubmit,
                           onCancel
                         }: Props) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    values,
    setValues,
    isValid,
    errors,
    touched,
    resetForm,
    setFieldValue
  } = useFormik<IAppointment>({
    validationSchema: appointmentSchema,
    initialValues: appointment,
    onSubmit: async (form) => {
      try {
        await api.put(`appointement/${appointment._id}`, {...form, patient: patientId})
        .then(response => {
          onSubmit({ ...form });
          notification.success({
            message: 'Rendez-vous mis à jour',
            description: 'Le rendez-vous a été mis à jour avec succès.',
            duration: 4,
          });
          resetForm();
        });
      } catch (error) {
        notification.error({
          message: 'Échec de la mise à jour',
          description: 'Une erreur est survenue lors de la mise à jour du rendez-vous. Veuillez réessayer.',
          duration: 4,
        });
        console.error('Erreur lors de la mise à jour du rendez-vous:', error);
      }
    }
  });

  useEffect(() => {
    setValues({ ...appointment });
  }, [appointment]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const onDateChange: DatePickerProps['onChange'] = (date) => {
    setFieldValue('date', date ? date.toISOString() : null);
  };

  const hasError = hasErrorFactory(touched, errors);

  const timeFormat = 'HH:mm';

  const handleStatusSelect = (value) => setFieldValue('status', value);

  return (
      <>
        <form onSubmit={handleSubmit}>

        <div className="form-group">
          <Input
            type="text"
            name="title"
            placeholder="Titre"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.title}
            className={hasError('title')}
          />
          {touched.title && errors.title && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.title)}
            </div>
          )}
        </div>

          <div className='form-group'>
            <label htmlFor='status'>Statut</label>
            <Select
                id='status'
                showSearch
                placeholder='Sélectionner le statut'
                optionFilterProp='children'
                filterOption={selectOptionFilter}
                onChange={handleStatusSelect}
                onBlur={() => setFieldTouched('status')}
                className={hasError('status')}
                value={values.status}
            >
              <Select.Option value="CRÉÉ" style={{color: "blue"}}>Créé</Select.Option>
              <Select.Option value="MANQUÉ" style={{color: "red"}}>Manqué</Select.Option>
              <Select.Option value="ANNULÉ" style={{color: "red"}}>Annulé</Select.Option>
              <Select.Option value="PRÉSENT" style={{color: "green"}}>Présent</Select.Option>
            </Select>
          </div>

          <Form.Item className='form-group'>
            <DatePicker
                value={values.date ? dayjs(values.date) : null}
                placeholder='Date'
                name='date'
                onChange={onDateChange}
                className={hasError('date')}
            />
          </Form.Item>

          <Form.Item className="">
            <TimePicker
                value={values.time ? dayjs(values.time, timeFormat) : null}
                placeholder='Heure'
                name='time'
                format={timeFormat}
                onChange={(time) => setFieldValue('time', time ? dayjs(time).format(timeFormat) : null)}
                className={hasError('time')}
            />
          </Form.Item>
          <div className='d-flex justify-content-between buttons-list settings-actions'>
            <Button danger onClick={handleCancel}>
              Annuler
            </Button>

            <Button disabled={!isValid} type='primary' htmlType='submit'>
              {submitText}
            </Button>
          </div>
        </form>
      </>
  );
};

export default AppointmentForm;
