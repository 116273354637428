import React, { useEffect, useState } from 'react';
import { Button, DatePicker, TimePicker, Input, Form, DatePickerProps, Select } from 'antd';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../../utils/hasError';
import { IAppointment, IControlPatient } from '../../../../../../interfaces/patient';
import api from "../../../../../../api";
import TextArea from 'antd/es/input/TextArea';

type Props = {
  patientId?: string;
  controlPatient?: IControlPatient;
  onSubmit: (cp: IControlPatient) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Ajouter un netoyage';
const emptyControlPatient = {
  patient: "",
  name:'',
  status:'',
  description:'',
  date: null,
};

const controlPatientSchema = Yup.object().shape({
  date: Yup.string().required('La date est requise'),
  name: Yup.string().required('Le nom est requis'),
  status: Yup.string().required('Le status est requis'),
  description: Yup.string().required('La déscription est requise'),
});

const ControlPatientForm = ({
  patientId,
  submitText = defaultSubmitText,
  controlPatient = emptyControlPatient,
  onSubmit,
  onCancel
}: Props) => {
  const {
    handleSubmit,
    handleChange,
    setFieldTouched,
    handleBlur,
    values,
    setValues,
    isValid,
    errors,
    touched,
    resetForm,
    setFieldValue
  } = useFormik<IControlPatient>({
    validationSchema: controlPatientSchema,
    initialValues: controlPatient,
    onSubmit: async (form) => {
      try {
        await api.post("cp", { ...form, patient: patientId }).then(response => {
          console.log(response.data)
          onSubmit({ ...form});
          resetForm();
          // window.location.reload();
        });
      } catch (error) {
        console.error(error)
      }
    }
  });
  const [error, setError]=useState('')

  useEffect(() => {
    setValues({ ...controlPatient });
  }, [controlPatient]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const onDateChange: DatePickerProps['onChange'] = (date) => {
    setFieldValue('date', date ? date.toISOString() : null);
  };

  const hasError = hasErrorFactory(touched, errors);

  const handleStatusSelect = (value: string) => setFieldValue('status', value);

  return (
    <>
      <form onSubmit={handleSubmit}>
        

        <div className="form-group">
          <Input
            type="text"
            name="name"
            placeholder="Nom"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.name}
            className={hasError('name')}
          />
          {touched.name && errors.name && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.name)}
            </div>
          )}
        </div>

        <Form.Item className='form-group'>
          <DatePicker
            defaultValue={values.date ? dayjs(values.date) : null}
            placeholder='Date'
            name='date'
            onChange={onDateChange}
            className={hasError('date')}
          />
          {touched.date && errors.date && (
            <div className="error-message" style={{ color: 'red' }}>
              {String(errors.date)}
            </div>
          )}
        </Form.Item>

        <div className='form-group'>
              <Select
                placeholder='Status'
                defaultValue={values.status}
                onChange={handleStatusSelect}
                className={hasError('status')}
                onBlur={() => setFieldTouched('status')}
              >
                <Select.Option value='BON' style={{ color: "blue" }}>BON</Select.Option>
                <Select.Option value='MOYEN' style={{ color: "green" }}>MOYEN</Select.Option>
                <Select.Option value='MAUVAIS' style={{ color: "tomato" }}>MAUVAIS</Select.Option>
              </Select>
              {touched.status && errors.status && (
                <div className="error-message" style={{ color: 'red' }}>
                  <br />
                  {String(errors.status)}
                </div>
              )}
            </div>

        <Form.Item className="">
          <TextArea
              name="description"
              placeholder="Déscription"
              onChange={handleChange}
              onBlur={handleBlur}
              defaultValue={values.description}
              className={hasError('description')}
            />
            {touched.description && errors.description && (
              <div className="error-message" style={{ color: 'red' }}>
                {String(errors.description)}
              </div>
            )}
        </Form.Item>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel}>
            Annuler
          </Button>

          <Button disabled={!isValid} type='primary' htmlType='submit'>
            {submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ControlPatientForm;
