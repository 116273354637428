import React from 'react';
import { Route, Navigate, RouteProps } from 'react-router-dom';
import { getUserRole, isLoggedIn } from './auth';

interface ProtectedRouteProps {
    element: React.ReactElement;
    allowedRoles: string[];
    children?: React.ReactNode;
  }
  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, allowedRoles, children }) => {
    const role = getUserRole();
    const loggedIn = isLoggedIn();
  
    return loggedIn && role && allowedRoles.includes(role) ? (
      React.cloneElement(element, {}, children)
    ) : (
      <Navigate to="/public/unauthorized" />
    );
  };
  const ProtectedDoctorRoute: React.FC<ProtectedRouteProps> = ({ element, allowedRoles, children }) => {
    const role = getUserRole();
    const loggedIn = isLoggedIn();

    return loggedIn && role && allowedRoles.includes(role) ? (
      React.cloneElement(element, {}, children)
    ) : (
      <Navigate to="/public/non-authorized" />
    );
  };
  export {
      ProtectedRoute,
      ProtectedDoctorRoute
  };
