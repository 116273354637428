import React, { useEffect, useState } from 'react';
import { usePageData } from '../../../../../hooks/usePage';
import { usePatients } from '../../../../../hooks/usePatients';
import PatientsTable from './PatientsTable';
import { IPageData } from '../../../../../interfaces/page';
import { useParams } from "react-router-dom";
import api from "../../../../../api";
import { useAdminFetchPageData } from "../../../../../hooks/useAdminPage";
import { IUser } from "../../../../../interfaces/user";
import { IPatient } from "../../../../../interfaces/patient";
import { log } from 'console';

const PatientsByDoctorPage = () => {
  const { editPatient, deletePatient } = usePatients();
  const { id } = useParams();
  const [doctor, setDoctor] = useAdminFetchPageData<IUser>(`doctor/${id}`, {
    address: "",
    email: "",
    firstName: "",
    lastName: "",
    username: ""
  });

  const [patientsByDoc, setPatientsByDoc] = useState<IPatient[]>([]);
  const pageData: IPageData = {
    title: `Dr. ${doctor.lastName}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Tableau de bord',
        route: '../admin/default-dashboard'
      },
      {
        title: 'Docteurs',
        route: '../admin/doctors'
      },
      {
        title: `Dr. ${doctor.lastName}`
      }
    ]
  };
  usePageData(pageData);

  useEffect(() => {
    const fetchPatientsByDoc = async () => {
      try {
        const response = await api.get(`patient/doctor/${id}`).then(response=>{
          console.log(response.data)
          setPatientsByDoc(response.data);
        });
      } catch (err) {
        console.error(err);
      }
    };
    fetchPatientsByDoc();
  }, [id]);

  const handleSetPatientsByDoc = (updatedPatients: IPatient[]) => {
    setPatientsByDoc(updatedPatients);
  };

  return (
      <div style={{ marginBottom: "40px" }}>
        {patientsByDoc && <PatientsTable
            onDeletePatient={deletePatient}
            onEditPatient={editPatient}
            patients={patientsByDoc}
            setPatients={handleSetPatientsByDoc}
        />}
      </div>
  );
};

export default PatientsByDoctorPage;
