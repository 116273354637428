import React, { useState, ReactNode } from 'react';
import { Table, Input } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { IAppointment, IPatient } from '../../../../../../../interfaces/patient';
// import "./AdminAppointmentsByPatientTable.style.scss";

const columns: ColumnProps<IAppointment>[] = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Titre',
    sorter: (a, b) => (a.title > b.title ? 1 : -1),
    render: (title) => (
      <strong>
        {title}
      </strong>
    ),
  },
  {
    key: 'date',
    dataIndex: 'date',
    title: 'Date',
    sorter: (a, b) => 
      new Date(a.date).getTime() - new Date(b.date).getTime(),
    render: (date) => {
  
      if (!date) return null;
  
      // Ensure the date is in ISO format (yyyy-mm-dd)
      const isoDate = date.split('T')[0];
  
      const [year, month, day] = isoDate.split('-');
  
      // Reformat the date as dd/mm/yyyy
      const formattedDate = `${day}/${month}/${year}`;
  
      return (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {formattedDate}
        </span>
      );
    }
  },
  {
    key: 'time',
    dataIndex: 'time',
    title: 'Horaire',
    render: (time) => (
        <strong>{time}</strong>
    )
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Statut',
    sorter: (a, b) => (a.status > b.status ? 1 : -1),
    render: (status) => (
      <strong
      style={
        status === 'MANQUÉ' || status === 'ANNULÉ'
          ? { color: '#ff4141' }
          : status === 'PRÉSENT'
          ? { color: '#00a81c' }
          : { color: '#3438ff' }
      }
    >
      {status === 'CRÉÉ'
        ? 'CRÉÉ'
        : status === 'MANQUÉ'
        ? 'MANQUÉ'
        : status === 'ANNULÉ'
        ? 'ANNULÉ'
        : status === 'PRÉSENT'
        ? 'PRÉSENT'
        : status}
    </strong>
    )
  },
];

type Props = {
  patient: IPatient;
  data: IAppointment[];
  actions?: (appointment: IAppointment) => ReactNode;
};

const AdminAppointmentsByPatientTable = ({ data, actions }: Props) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = data.filter(appointment => {
    const isoDate = appointment.date ? appointment.date.split('T')[0] : '';
    const [year, month, day] = isoDate.split('-');
    const formattedDate = `${day}/${month}/${year}`;

    // Allow partial matching for dates (dd/mm/yyyy) and other fields
    return (
      formattedDate.includes(searchText) || 
      appointment.title?.toLowerCase().includes(searchText.toLowerCase()) || 
      appointment.status.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
      <>
        <label className="search-label">Rechercher</label>
        <Input
          className="search-input"
          placeholder="Recherche par titre, par statut ou par date: dd/mm/aaaa"
          value={searchText}
          onChange={handleSearch}
          style={{ marginBottom: 20, fontSize: "17px", border: "1px solid #ccc" }}
        />
        <Table
            rowKey='_id'
            dataSource={filteredData}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
        />
      </>
  );
};

export default AdminAppointmentsByPatientTable;
