import {jwtDecode} from "jwt-decode";

const audiologistToken = localStorage.getItem("audiologist_token");
let decodedToken = "";

if (audiologistToken) {
    try {
        decodedToken = jwtDecode(audiologistToken);
    } catch (error) {
        console.error("Failed to decode token:", error);
    }
} else {
    console.warn("No token found in localStorage");
}
console.log(decodedToken)
export {
    decodedToken,
    audiologistToken
};