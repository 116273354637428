import React, { useEffect, useState } from 'react';

import { Button, notification } from 'antd';

import EditAppointment from './Appointment/EditAppointment';
import AddAppointment from './Appointment/AddAppointment';

import PageAction from '../../../../../layout/components/page-action/PageAction';
import { usePageData } from '../../../../../hooks/usePage';

import { IAppointment, IControlPatient, IPatient, IReport } from '../../../../../interfaces/patient';
import { IPageData } from '../../../../../interfaces/page';
import { useAdminFetchPageData } from "../../../../../hooks/useAdminPage";
import AdminAppointmentsTable from "./Appointment/AppointmentsTable";
import { NavLink, useParams, Link } from "react-router-dom";
import AdminReportsByPatientTable from "./Report/ReportsTable";

import "./patient-details.scss"
import AddReport from "./Report/AddReport";
import api from "../../../../../api";
import { IUser } from "../../../../../interfaces/user";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AdminControlPatientTable from './ControlPatient/ControlPatientsTable';
import AddControlPatient from './ControlPatient/AddControlPatient';
import { add } from 'date-fns';
import EditCP from './ControlPatient/EditControlPatient';
import EditReport from './Report/EditReport';

const AdminDetailsPatientPage = () => {
  const { patientId } = useParams();
  const [appointments, setAppointments] = useAdminFetchPageData<IAppointment[]>(
    `appointement/patient/${patientId}`,
    []
  );
  const [controlPatients, setControPatients] = useAdminFetchPageData<IControlPatient[]>(
    `cp/patient/${patientId}`,
    []
  );
  const [patient, setPatient] = useAdminFetchPageData<IPatient>(
    `patient/${patientId}`,
    { address: "", email: "", firstName: "", lastName: "" }
  );
  const [doctor] = useAdminFetchPageData<IUser>(
    `doctor/${patient.doctor}`,
    { address: "", email: "", firstName: "", lastName: "", username: "" }
  );
  const [reports, setReports] = useAdminFetchPageData<IReport[]>(
    `report/patient/${patientId}`,
    []
  );
  const title  = patient.sexe === 'homme' ? `M.` : `Mme`;
  const pageData: IPageData = {
    title: `Détails de ${title} ${patient.lastName}`,
    fulFilled: true,
    breadcrumbs: [
      {
        title: 'Tableau de bord',
        route: '../admin/default-dashboard'
      },
      {
        title: 'Médecins',
        route: '../admin/doctors'
      },
      {
        title: `Dr. ${doctor.lastName}`,
        route: `../admin/doctor-profile/${patient.doctor}`
      },
      {
        title: `Détails de ${title} ${patient.lastName}`
      }
    ]
  };
  usePageData(pageData);
  useEffect(() => {
    console.log(controlPatients);
  }, [appointments, controlPatients]);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedCP, setSelectedCP] = useState(null);
  const [addingAppointmentModalVisibility, setAddingAppointmentModalVisibility] = useState(false);
  const [addingReportModalVisibility, setAddingReportModalVisibility] = useState(false);
  const [addingControlPatientModalVisibility, setAddingControlPatientModalVisibility] = useState(false);
  const [activeReports, setActiveReports] = useState<string[]>([]);

  const MySwal = withReactContent(Swal);

  const handleDeleteAppointment = (appointment: IAppointment) => {
    MySwal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce rendez-vous ?',
      text: 'Cette action est irréversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`appointement/${appointment._id}`);
          const newAppointments = appointments.filter((el) => el._id !== appointment._id);
          setAppointments(newAppointments);

          // Afficher une notification en cas de succès
          notification.success({
            message: 'Rendez-vous supprimé',
            description: 'Le rendez-vous a été supprimé avec succès.',
            duration: 4,
          });
        } catch (error) {
          console.error('Erreur lors de la suppression du rendez-vous:', error);
          notification.error({
            message: 'Erreur',
            description: 'Une erreur est survenue lors de la suppression du rendez-vous. Veuillez réessayer.',
            duration: 4,
          });
        }
      }
    });
  };

  const handleDeleteCP = (cp: IControlPatient) => {
    MySwal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce Netoyage ?',
      text: 'Cette action est irréversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`cp/${cp._id}`);
          const newControlPatients = controlPatients.filter((el) => el._id !== cp._id);
          setControPatients(newControlPatients);

          // Afficher une notification en cas de succès
          notification.success({
            message: 'netoyage supprimé',
            description: 'Le netoyage a été supprimé avec succès.',
            duration: 4,
          });
        } catch (error) {
          console.error('Erreur lors de la suppression du netoyage:', error);
          notification.error({
            message: 'Erreur',
            description: 'Une erreur est survenue lors de la suppression du netoyage. Veuillez réessayer.',
            duration: 4,
          });
        }
      }
    });
  };

  const handleEdit = (appointment: IAppointment) => {
    const editedAppointments = appointments.map((el) =>
      el !== selectedAppointment ? el : appointment
    );
    setAppointments(editedAppointments);
    setSelectedAppointment(null);
  };

  const handleEditCp = (cp: IControlPatient) => {
    console.log("cp");
    console.log(cp);
    
    const editedCps = controlPatients.map((el) =>
      el._id !== selectedCP._id ? el : cp
    );
    setControPatients(editedCps);
    setSelectedCP(null);
  };


  const openAddingAppointmentModal = () => setAddingAppointmentModalVisibility(true);
  const openAddingReportModal = () => setAddingReportModalVisibility(true);
  const openAddingControlPatientModal = () => setAddingControlPatientModalVisibility(true);

  const addAppointment = (appointment: IAppointment) => {
    setAppointments([appointment, ...appointments]);
    setAddingAppointmentModalVisibility(false);
  };

  const addControlPatient = (control: IControlPatient) => {
    setControPatients([control, ...controlPatients]);
    setAddingControlPatientModalVisibility(false);
  };
  const addReport = (report: IReport) => {
    setReports([report, ...reports]);
    setAddingReportModalVisibility(false);
  };

  const closeAddingAppointmentModal = () => setAddingAppointmentModalVisibility(false);
  const closeAddingControlPatientModal = () => setAddingControlPatientModalVisibility(false);
  const closeAddingReportModal = () => setAddingReportModalVisibility(false);

  const openEditModal = (appointment: IAppointment) => setSelectedAppointment(appointment);
  const openEditModalCP = (cp: IControlPatient) => setSelectedCP(cp);

  const closeModal = () => {
    setSelectedAppointment(null);
  };

  const closeCpModal = () => {
    setSelectedCP(null);
  };

  const detailsAppointmentActions = (appointment: IAppointment) => (
    <div className='buttons-list nowrap'>
      <Button onClick={openEditModal.bind({}, appointment)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={handleDeleteAppointment.bind({}, appointment)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div>
  );

  const detailsCPActions = (cp: IControlPatient) => (
    <div className='buttons-list nowrap'>
      <Button onClick={openEditModalCP.bind({}, cp)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={handleDeleteCP.bind({}, cp)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div>
  );

  const handleSetReports = (updatedReports: IReport[]) => {
    console.log(updatedReports.length)
    setReports(updatedReports)
  };

  const updateActiveReports = (newActiveReports: string[]) => {
    setActiveReports(newActiveReports);
  };

  return (
    <>
      <div className="title-heading">
        <h2>Rendez-vous :</h2>

        <NavLink to={""} onClick={openAddingAppointmentModal}>
          <span className={`icofont icofont-plus-circle`} style={{ fontSize: 60 }} />
        </NavLink>
      </div>
      <AdminAppointmentsTable patient={patient} data={appointments} actions={detailsAppointmentActions} />

      {/* <PageAction icon='icofont icofont-ui-calendar' type={'primary'} /> */}
      <NavLink to={"/admin/patient-calendar/" + patientId} className='page-action' >
        <Button className='page-action' size='large' shape='circle'>
          <span className={`icofont icofont-ui-calendar`} style={{ fontSize: 40 }} />
        </Button>
      </NavLink>
      <AddAppointment
        patientId={patientId}
        onClose={closeAddingAppointmentModal}
        visible={addingAppointmentModalVisibility}
        onSubmit={addAppointment}
      />

      <EditAppointment
        appointment={selectedAppointment}
        visible={!!selectedAppointment}
        onClose={closeModal}
        onEdited={handleEdit}
      />
      <div className="title-heading report">
        <h2>Rapports:</h2>

        <div className='btn-container'>
          {activeReports.length === 2 && (
            <Link
              to={`../compare-report/?report1=${activeReports[0]}&report2=${activeReports[1]}`}
            >
              <Button>
                Voir la comparaison
              </Button>
            </Link>
          )}

          <span className='icofont icofont-plus-circle'  style={{ fontSize: 60 }}  onClick={openAddingReportModal} />
        </div>
      </div>


      <AddReport
        patientId={patientId}
        onClose={closeAddingReportModal}
        visible={addingReportModalVisibility}
        onSubmit={addReport}
      />

      

      <AdminReportsByPatientTable
        setReports={handleSetReports}
        patient={patient}
        data={reports}
        updateActiveReports={updateActiveReports}
      />

      <div className="title-heading">
        <h2>Utilisation & Nettoyage :</h2>

        <NavLink to={""} onClick={openAddingControlPatientModal}>
          <span className={`icofont icofont-plus-circle`} style={{ fontSize: 60 }} />
        </NavLink>
      </div>
      {/* <AdminAppointmentsTable patient={patient} data={appointments} actions={detailsAppointmentActions} /> */}
      <AdminControlPatientTable patient={patient} data={controlPatients} actions={detailsCPActions} />


      <EditCP
        controlPatient={selectedCP}
        visible={!!selectedCP}
        onClose={closeCpModal}
        onEdited={handleEditCp}
      />
      <AddControlPatient
        patientId={patientId}
        onClose={closeAddingControlPatientModal}
        visible={addingControlPatientModalVisibility}
        onSubmit={addControlPatient}
      />

    </>
  );
};

export default AdminDetailsPatientPage;
